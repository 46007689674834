import { Layout } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import ResHeader from 'src/components/layout/ResHeader';
import BackgroundImage from 'src/static/background.svg';
import AdminSidebar from './AdminSidebar';
import DealerSidebar from './DealerSidebar';

const { Content } = Layout;

const Template = props => (
  <div>
    <Layout style={{ minHeight: '100vh' }}>
      <ResHeader />
      <Layout>
        {props.user.type === 'branch' ? (
          <DealerSidebar />
        ) : props.user.type === 'admin' ? (
          <AdminSidebar />
        ) : null}
        <Layout
          style={{
            ...(props.background && {
              backgroundImage: `url(${BackgroundImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat'
            }),
            marginLeft: props.collapsed ? 0 : 0,
            marginTop: 60,

            transition: 'all 0.2s'
          }}
        >
          <Content
            style={{
              transition: 'all 0.2s',
              margin: 20,
              marginLeft: 20
            }}
          >
            {props.children}
          </Content>
          {/* <ResFooter /> */}
        </Layout>
      </Layout>
    </Layout>
  </div>
);

const mapStateToProps = state => {
  return {
    collapsed: state.status.sidebarCollapsed,
    user: state.user.user
  };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Template);
