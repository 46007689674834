export default {
  HamMenu1: 'Home',
  HamMenu2: 'About Us',
  HamMenu3: 'Features',
  HamMenu4: 'Pricing',
  HamMenu5: 'Contact Us',
  HamMenu6: 'FAQ',
  HamMenu7: 'Services',
  HomeTitle: 'Home Page',
  HomeText1a: 'Your future',
  HomeText1b: 'starts here',
  HomeText2:
    'Easy-accessible quality learning material for all Grade 4 to 6 subjects (Afrikaans, English, Life Skills, Mathematics, Natural Sciences and Technology, and Social Sciences). Learn anywhere, any time! Available in English and Afrikaans.',
  HomeBegin: 'View Demo',
  HomeFreeLesson: 'Free Lesson',
  HomeRegister: 'Register',
  Signup: 'Sign Up',
  Signin: 'Sign In',
  Signout: 'Sign Out',
  Accept: 'Accept',
  Update: 'Update',
  Next: 'Next',
  Cancel: 'Cancel',
  Continue: 'Continue',
  TryAgain: 'Try Again',
  CloseVideo: 'Close Video',
  AboutTitle: 'About Us',
  AboutText1:
    'We are a South African company looking into a niche market, providing personalised learning that can be accessed online through mobile devices, laptops, and tablets or be downloaded for offline usage on a smart device.',
  AboutText2: ' ',
  AboutText3:
    'The initial start-up will cater for subjects for Grade 4 – 6 in English and Afrikaans, with long-term expansion plans to include other subjects, grades and languages.',
  AboutText4:
    'Our highly skilled team has a vibrant and creative vision to help learners learn at their own pace.',
  FeaturesTitle: 'Features',
  Offline: 'Offline',
  OfflineText1:
    'The future of online is offline. View all your content while being offline on your smart device.',
  'Track&Report': 'Track & Report',
  'Track&ReportText1':
    'All lessons are tracked and will show your progress for each lesson.',
  MobileLessons: 'Mobile lessons',
  MobileLessonsText:
    'Learn anywhere, anytime. Available on cellphone, tablet and desktop.',
  InteractiveLessons: 'Interactive lessons',
  InteractiveLessonsText:
    'Our lessons are very interactive with videos, animation, infographics, voice overs and so much more.',
  RewardSystem: 'Reward System',
  RewardSystemText:
    'Collect badges after each lesson to climb the Infonerd leaderboard.',
  FlexibleSessions: 'Flexible Sessions',
  FlexibleSessionsText:
    'Not everybody learns at the same pace. Repeat lessons as much as you like.',
  PricingTitle: 'Payment Options',
  PricingText: 'Choose a plan that fits your needs.',
  MonthlyTitle: 'Monthly',
  AnnuallyTitle: 'Annual',
  MonthlyText1: "Electronic accessibility to all your grade's subjects",
  MonthlyText2: 'Any-time access to your lessons',
  MonthlyText3: 'Development report',
  MonthlyText4: 'Videos',
  MonthlyText5: 'Audio book available on all lessons',
  MonthlyText6: 'A PDF summary of all lessons',
  MonthlyText7: 'Assessment after each lesson',
  MonthlyText8: 'Practice papers',
  MonthlyText9: 'English and Afrikaans speeches',
  MonthlySelect: 'Select',
  MonthlyText: 'R199 R99 Black Friday',
  AnnuallyText: 'R1999 R999 Black Friday',
  BlackFridayTitle1: 'Black Friday 50% afslag!',
  BlackFridayTitle2: 'Maandeliks',
  BlackFridayTitle3: 'Jaarliks',
  BlackFridayText1:
    'If you sign up today you will pay a once-off registration fee of R99, then a recurring amount of R99 will run from January 2019.',
  BlackFridayText2:
    'If you sign up today you will pay a once-off annual fee of R999 for the whole year of 2019.',
  ContactTitle: 'Contact Us',
  ContactText0: 'Unit 24',
  ContactText1: 'Oakhurst Office Park',
  ContactText2: '1 Grippen rd',
  ContactText3: 'Boksburg',
  ContactText4: 'Bartlett',
  ContactText5: 'Your Name',
  ContactText6: 'Your Email address',
  ContactText7: 'Your Phone number',
  ContactText8: 'Enter your message here',
  ContactText9: 'Please input your name!',
  ContactText10: 'Please input your email!',
  ContactText11: 'Please input your phone number!',
  ContactText12: 'Please input your message!',
  ContactText13: 'Name...',
  ContactText14: 'Email...',
  ContactText15: 'Phone number...',
  ContactText16: 'Message...',
  NewsletterText1: 'Sign Up',
  ContactSend: 'Send',
  Subscribe: 'Register for our newsletter',
  TCText: 'Terms & Conditions',
  FAQHeading: 'Frequently Asked Questions',
  FAQText: 'If you have questions, find the answers here.',
  FAQPlaceholder: 'Search Asked Question',
  SignUpInput1: 'Parent Full Name',
  SignUpInput2: 'Parent e-mail',
  SignUpInput3: 'Password',
  SignUpInput4:
    'School user? (Only required if you are part of a school subscription)',
  SignUpInput5: 'Already have an account?',
  SignUpInput6: 'School pin',
  SignUpInput7: 'Parent Mobile Number',
  SignUpInput8: 'Where did you hear about us?',
  SignUpValEr1: 'Please input your name and surname!',
  SignUpValEr2: 'Please input your e-mail!',
  SignUpValEr3: 'Please input your password!',
  SignUpValEr4: 'You need to accept the terms and conditions!',
  SignUpValEr5: 'Please input your school pin!',
  SignUpValEr6: 'Please input your mobile number!',
  SignUpValEr7: 'Please choose one!',
  SignInInput1: 'Parent e-mail',
  SignInInput2: 'Password',
  SignInInput3: 'Remember me',
  SignInInput4: "Don't have an account?",
  SignInValEr1: 'Please input your e-mail!',
  SignInValEr2: 'Please input your password!',
  SidebarText1: 'My Dashboard',
  SidebarText2: 'Profiles',
  SidebarText3: 'My Account',
  SidebarText4: 'Report',
  SidebarText5: 'Leaderboard',
  SidebarEr1: 'No profile selected',
  SidebarEr2:
    'You first need to select a profile before you can view your dashboard.',
  ProfilesListText1: 'Create Profile',
  ProfilesAddInput1: 'Full Name and Surname',
  ProfilesAddInput2: 'School',
  ProfilesAddInput3: 'Gender',
  ProfilesAddInput3a: 'Male',
  ProfilesAddInput3b: 'Female',
  ProfilesAddInput4: 'Age',
  ProfilesAddInput5: 'Grade',
  ProfilesAddInput5a: 'Grade 4',
  ProfilesAddInput5b: 'Grade 5',
  ProfilesAddInput5c: 'Grade 6',
  ProfilesAddValEr1: 'Please input your name and surname!',
  ProfilesAddValEr2: 'Please input your school!',
  ProfilesAddValEr3: 'Please input your gender!',
  ProfilesAddValEr4: 'Please input your age!',
  ProfilesAddValEr5: 'Please input your grade!',
  ProfilesAddValEr6: 'Name and surname is required!',
  ProfilesAddValEr7: 'School is required!',
  ProfilesAddValEr8: 'Gender is required!',
  ProfilesAddValEr9: 'Age is required!',
  ProfilesAddValEr10: 'Grade required!',
  AccountText1: 'My Account',
  AccountText2: 'Subscription',
  AccountText3: 'Account Details',
  SubscriptionText1: 'Grade',
  SubscriptionText2: 'Date Subscribed',
  SubscriptionText3: 'Payment Method',
  SubscriptionText4: 'Change',
  SubscriptionText5: 'View Payment History',
  SubscriptionText6: 'No Subscription',
  SubscriptionText7: 'None',
  SubscriptionText8: 'Suspended',
  SubscriptionText9: 'Subscribe',
  SubscriptionText10: 'Unsubscribe',
  SubscriptionText11: 'Monthly',
  SubscriptionText12: 'Month',
  SubscriptionText13: 'Days remaining',
  SubscriptionText14: 'Active',
  SubscriptionText15: 'Expired',
  SubscriptionText16: 'Annual',
  SubscriptionText17: 'Cancelled',
  SubscriptionText18: 'Resubscribe',
  PaymentText1: 'Month',
  PaymentText2: 'Monthly',
  PaymentText3: 'Grade',
  PaymentText4: 'Duration',
  PaymentText5: 'Change Payment Method',
  PaymentText6: 'Annual',
  PaymentText7: 'Make Payment',
  SummaryText1: 'Subscription Summary',
  SummaryText2: 'Name',
  SummaryText3: 'Grade',
  SummaryText4: 'Payment Method',
  SummaryText5: 'Duration',
  SummaryText6: 'Amount',
  CompletedText1: 'Payment Successfull',
  CompletedText2: 'Payment Cancelled',
  CompletedText3: 'A receipt will be sent to your email.',
  CompletedText4: 'Please try again.',
  PaymentHeading: 'Payment',
  Selection: 'Selection',
  Summary: 'Summary',
  Confirmation: 'Confirmation',
  HistoryText1: 'Payment History',
  HistoryText2: 'Id',
  HistoryText3: 'Status',
  HistoryText4: 'Amount',
  HistoryText5: 'Type',
  HistoryText6: 'Date',
  FeatureLockedText1: 'Feature Locked',
  FeatureLockedText2: 'This feature will be released in 2019.',
  HeaderText1: 'Profile Settings',
  HeaderText2: 'Account Settings',
  HeaderText3: 'Search lessons',
  Notify1: 'This feature will unlock in 2019',
  Notify2: 'Successfully unsubscribed ',
  Notify3: 'Login Error',
  Notify4: 'Message was successfully sent.',
  Notify5: 'Message not sent.',
  Notify6: 'Successfully signed up',
  Notify7: 'Sign Up Error',
  Notify8: 'Lesson Error',
  Notify9: 'Successfully added profile',
  Notify10: 'Profile Error',
  Notify11: 'Success',
  Notify12: 'Download successfull',
  Notify13: 'Remove download successfull',
  ProfilesEditText1: 'Edit Profile',
  ProfilesEditText2: 'Update Profile',
  ProfileCardText1: 'Choose profile for payment options',
  ServicesTitle: 'Services',
  ServicesHead1: 'Question Papers',
  ServicesHead2: 'Speeches',
  ServicesHead3: 'Summaries',
  ServicesHead4: 'Exercises',
  ServicesHead5: 'Public Speaking',
  ServicesText1:
    "Question papers can play an important role in your child's preparation for a test or exam.",
  ServicesText2:
    'Speeches are written based on the parents requirements, subject, duration and grade of your child.',
  ServicesText3:
    'All our lessons are summarised using various CAPS-based textbooks, making learning easier. Our summaries are available on mobile, tablet, desktop and laptop.',
  ServicesText4:
    'Our lesson exercises are a very good tool when it comes to preparing for exams and tests.',
  ServicesText5:
    'Public speaking is not included in the full package, but can be ordered separately. Contact our friendly staff for further information on this service offering.',
  ServicesHead6: 'Assignment Information',
  ServicesText6:
    "Information is collected according to the instructions received. The completion of the task is still the student's responsibility.",
  DashboardGrade: 'Grade',
  DashboardOverview: 'Overview',
  DashboardCompLes: 'Completed Lessons',
  DashboardGold: 'Gold Trophies',
  DashboardSilver: 'Silver Trophies',
  DashboardBronze: 'Bronze Trophies',
  DashboardBadges: 'Badges',
  DashboardSubjects: 'Subjects',
  DashboardStart: 'Start',
  DashboardCont: 'Continue',
  DashboardQuizScore: 'Quiz score',
  DashboardPassed: 'Passed',
  DashboardLesson: 'Lesson',
  DashboardTerms: 'Term',
  LessonsDownloaded: 'Downloaded',
  LessonsNotDownloaded: 'Download',
  OfflineMessage:
    'Offline - Only downloaded lessons will show and your progress will be synced once you go online',
  OfflineMessage2: 'Offline - Your progress will be synced once you go online',
  DownloadMessageHeading: 'Download Lessons',
  DownloadMessage:
    'Please note that lessons are 5MB to 40MB in size each, data charges may be applicable. This information will only show once if you accept.',
  ForgotPassword: 'Forgot password?',
  ForgotPasswordReset: 'Reset',
  ResetPassword: 'Reset Password',
  PassResetSend:
    'A password reset email was sent to the registered e-mail address.',
  PassResetSuccess: 'Password changed, use the new password to sign in.',
  New: 'New',
  UpdateProfile: 'Successfully updated.',
  ImageSmaller: 'Image must be smaller than 4MB.',
  PinWrong: 'Please enter a valid pin',
  PinError: 'Pin Error',
  NoPin: 'No pin found please set a pin. 4 - 6 characters',
  EnterPin: 'Please enter pin to continue',
  NewPin: 'Please set a new pin. 4 - 6 characters',
  NewPinDone: 'New pin set',
  ForgotPin: 'Forgot pin?',
  ResetPin: 'Reset',
  Report: 'Report',
  ReportHeading: 'Report',
  ReportSelectProfile: 'Choose Profile',
  ReportSelectSubject: 'Choose Subject',
  ReportAllSubjects: 'All Subjects',
  ReportAvgQuiz: 'Average Quiz Score: ',
  ReportLesComplete: 'Lessons Completed: ',
  ReportTerm1: 'Term 1',
  ReportTerm2: 'Term 2',
  ReportTerm3: 'Term 3',
  ReportTerm4: 'Term 4',
  ReportOutOf: 'out of',
  ReportChildName: "Child's Name",
  ReportGrade: 'Grade',
  ReportSubject: 'Subject',
  Print: 'Print',
  BackAgain: 'Press back again to close the application',
  Friend: 'Friend',
  Pamphlet: 'Flyer',
  Radio: 'Radio',
  LeaderboardHeading: 'Leaderboard',
  LeaderboardSelectGrade: 'Select grade',
  DeleteProfile: 'Delete Profile',
  DeleteProfileConfirm: 'Are you sure you want to delete the profile?',
  DeleteProfileDone: 'Profile deleted',
  DeleteProfileError: 'Profile delete error',
  NotificationHeading: 'Notifications',
  Yes: 'Yes',
  No: 'No',
  DashboardRank: 'Leaderboard Position',
  Done: 'Done!',
  CloseWindow: 'You can close this window.',
  RemoveDownloadMessageHeading: 'Remove Downloaded Lesson',
  RemoveDownloadMessage:
    'Are you sure you want to remove the downloaded lesson?'
};
