import { Cookies } from 'react-cookie';
import config from 'src/config';
export const getToken = () => {
  const cookies = new Cookies();
  return cookies.get('token');
};
export function findAllUsers(pagination) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/findallusers?page=${pagination}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_ALLUSERS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllUserBranches() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/findalluserbranches`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_ALLUSERS_BRANCHES',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function addUser(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/adduser`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(values)
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteUser(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/deleteuser`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllCompanies(pagination, search) {
  let searchValue = search || undefined;
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        `${
          config.API
        }/admin/findallcompanies?page=${pagination}&search=${searchValue}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_ALLCOMPANIES',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function assignToUser(id, repId, type) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/admin/assigntouser`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id, repId: repId, type: type })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to assign to user' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function addCompany(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        `${config.API}/admin/addcompany`,

        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(values)
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteCompany(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/deletecompany`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllBranches(pagination, search) {
  let searchValue = search || undefined;
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        `${
          config.API
        }/admin/findallbranches?page=${pagination}&search=${searchValue}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_ALLBRANCHES',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function addBranch(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/addbranch`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(values)
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteBranch(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/deletebranch`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function completeDeal(id, failed) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/admin/completedeal`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id, failed: failed })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to complete deal' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function updateFollowUp(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/admin/updatefollowup`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to update follow-up date' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function updateNotes(id, note) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/admin/updatenotes`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id, note: note })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to update follow-up date' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findDealDetails(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/finddealdetails?id=${id}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'ADMIN_FIND_DEALDETAILS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findDealBidInfo(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/finddealbidinfo?id=${id}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'ADMIN_FIND_DEALBIDINFO',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllDeals() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/findalldeals`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_ALLDEALS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findDealDeals(type) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/finddealdeals?type=${type}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_DEALDEALS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findApprovedDeals() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/findapproveddeals`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_APPROVEDDEALS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionHistoryDeals(dateFrom, dateTo) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        `${
          config.API
        }/admin/findauctionhistorydeals?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_AUCTIONHISTORYDEALS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setDealDetailsId(id) {
  return dispatch =>
    dispatch({
      type: 'ADMIN_SET_DEALDETAILSID',
      data: id
    });
}

export function setFromHistory(boolean) {
  return dispatch =>
    dispatch({
      type: 'ADMIN_SET_FROMHISTORY',
      data: boolean
    });
}

export function findAllDealers() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/findalldealers`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_ALLDEALERS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteDealer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/deletedealer`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function approveDealer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/approvedealer`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function activateBranch(id, active) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/activatebranch`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id, active: active })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deactivateDealer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/deactivatedealer`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function activatePrices(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        `${config.API}/admin/activateprices`,

        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deactivatePrices(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/deactivateprices`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findCalendar() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/findcalendar`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_CALENDAR',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function saveCalendar(calendar) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/admin/savecalendar`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ calendar: calendar })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}
