import { Cookies } from 'react-cookie';
import config from 'src/config';
const getToken = () => {
  const cookies = new Cookies();
  return cookies.get('token');
};
export function addLead(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/leads/addlead`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(values)
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to add lead' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function saveLead(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/leads/savelead`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(values)
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to edit lead' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteLead(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/leads/deletelead`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      })
        .then(res => {
          if (!res.ok) {
            return reject({ message: 'Failed to delete lead' });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findMyLeads(type) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(`${config.API}/leads/findmyleads?type=${type}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to fetch leads' });
        }
        return resolve(
          dispatch({
            type: 'LEADS_FIND_MYLEADS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function payLead(lead) {
  var params = {
    merchant_id: config.MERCHANT_ID,
    merchant_key: config.MERCHANT_KEY,
    return_url: config.LEAD_RETURN_URL,
    cancel_url: config.LEAD_CANCEL_URL,
    notify_url: `${config.API}/leads/invoicelead`,
    amount: lead.price,
    item_name:
      lead.type === 'specials'
        ? 'Alarm Lead Service Fee'
        : 'Guarding Lead Service Fee',
    item_description: 'Fee to unlock a lead',
    custom_str1: lead._id
  };

  return dispatch =>
    new Promise(async (resolve, reject) => {
      return fetch(`${config.API}/leads/gethash`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(params)
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to process payment' });
        }
        let response = await res.json();
        params.signature = response.hash;
        var form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', config.PAYFAST_URL);
        for (var key in params) {
          if (params.hasOwnProperty(key)) {
            var hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', params[key]);
            form.appendChild(hiddenField);
          }
        }
        document.body.appendChild(form);
        form.submit();

        // return resolve(await res.text());
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function invoiceLead(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/leads/invoicelead`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to edit lead' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function updateFollowUp(id, values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/leads/updatefollowup`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id, values: values })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to update follow-up date' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function completeLead(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/leads/completelead`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to complete lead' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}
