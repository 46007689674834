export const initialState = {
  myDeals: [],
  basketDeals: [],
  basketDealIds: [],
  auctionDeals: [],
  auctionDealIds: [],
  dealDoneDeals: [],
  wonDeals: [],
  lostDeals: [],
  editDeal: undefined,
  dealDetails: undefined,
  dealDetailsId: '',
  customTime: undefined,
  timeStamp: undefined
};

export default function dealsReducer(state = initialState, action) {
  switch (action.type) {
    case 'DEALS_FIND_MYDEALS': {
      if (action.data) {
        const myDeals = action.data.myDeals;
        const totalItems = action.data.totalItems;
        return {
          ...state,
          myDeals: myDeals,
          totalDeals: totalItems
        };
      }
      return initialState;
    }
    case 'DEALS_FIND_BASKETDEALS': {
      if (action.data) {
        const basketDeals = action.data.basketDeals;
        let basketDealIds = [];
        basketDeals.forEach(deal => {
          basketDealIds.push(deal._id);
        });
        return {
          ...state,
          basketDeals: basketDeals,
          basketDealIds: basketDealIds
        };
      }
      return initialState;
    }
    case 'DEALS_FIND_DEALDETAILS': {
      if (action.data) {
        const dealDetails = action.data.dealDetails;

        return {
          ...state,
          dealDetails: dealDetails
        };
      }
      return initialState;
    }
    case 'DEALS_FIND_AUCTIONDEALS': {
      if (action.data) {
        const auctionDeals = action.data.auctionDeals;
        let auctionDealIds = [];
        auctionDeals.forEach(deal => {
          auctionDealIds.push(deal._id);
        });
        return {
          ...state,
          auctionDeals: auctionDeals,
          auctionDealIds: auctionDealIds
        };
      }
      return initialState;
    }
    case 'DEALS_FIND_DEALDONEDEALS': {
      if (action.data) {
        const dealDoneDeals = action.data.dealDoneDeals;
        return {
          ...state,
          dealDoneDeals: dealDoneDeals
        };
      }
      return initialState;
    }
    case 'DEALS_FIND_WONDEALS': {
      if (action.data) {
        const wonDeals = action.data.wonDeals;
        return {
          ...state,
          wonDeals: wonDeals
        };
      }
      return initialState;
    }
    case 'DEALS_FIND_LOSTDEALS': {
      if (action.data) {
        const lostDeals = action.data.lostDeals;
        return {
          ...state,
          lostDeals: lostDeals
        };
      }
      return initialState;
    }
    case 'DEALS_SET_EDITDEAL': {
      if (action.data) {
        const editDeal = action.data;

        return {
          ...state,
          editDeal: editDeal
        };
      }
      return initialState;
    }
    case 'DEALS_SET_DEALDETAILSID': {
      if (action.data) {
        const id = action.data;
        return {
          ...state,
          dealDetailsId: id
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_LIST': {
      if (action.data) {
        let auctionDeals = state.auctionDeals;
        let updatedDeals = action.data.deals;
        auctionDeals.forEach(obj => {
          obj.highestBid = updatedDeals.find(o => o._id === obj._id).highestBid;
          obj.addAuctionMinutes = updatedDeals.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedDeals.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          auctionDeals: auctionDeals,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_BASKET': {
      if (action.data) {
        let basketDeals = state.basketDeals;
        let updatedDeals = action.data.deals;
        basketDeals.forEach(obj => {
          obj.highestBid = updatedDeals.find(o => o._id === obj._id).highestBid;
          obj.addAuctionMinutes = updatedDeals.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedDeals.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          basketDeals: basketDeals,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_SINGLE': {
      if (action.data) {
        let dealDetails = state.dealDetails || {};
        dealDetails.highestBid = action.data.deal.highestBid;
        dealDetails.addAuctionMinutes = action.data.deal.addAuctionMinutes;
        const timeStamp = action.data.timeStamp;

        return {
          ...state,
          dealDetails: dealDetails,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
