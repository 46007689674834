/* global document */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Spin } from 'antd';

import configureStore from 'src/redux/store';
import 'src/styles/global.css';
import Routes from 'src/routes/routes';
import GlobalStyles from './styles/global';
import I18n from './i18n/i18n';

// Components

// HIJACK CONSOLE AND ERROR LOG FOR PRODUCTION
if (process.env.NODE_ENV !== 'development')
  window.console = {
    log: () => {},
    error: () => {},
    warn: () => {}
  }; //HIJACK CONSOLE LOG

const { persistor, store, history } = configureStore();
// persistor.purge(); // Debug to clear persist

const rootElement = document.getElementById('root');

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={<Spin spinning />} persistor={persistor}>
      <Routes history={history} />
    </PersistGate>
    <GlobalStyles />
    <I18n />
  </Provider>
);

render(<Root />, rootElement);

// if (window.cordova) {
// document.addEventListener(
//   'deviceready',
//   () => {
//     render(<Root />, rootElement);
//     serviceWorker.register();
//   },
//   false
// );
// }
