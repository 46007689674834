export const initialState = {
  myGuardingLeads: [],
  myEstimateLeads: [],
  basketLeads: [],
  basketLeadIds: [],
  auctionLeads: [],
  auctionLeadIds: [],
  leadDoneLeads: [],
  wonLeads: [],
  lostLeads: [],
  editLead: undefined,
  leadDetails: undefined,
  leadDetailsId: '',
  customTime: undefined,
  timeStamp: undefined
};

export default function leadsReducer(state = initialState, action) {
  switch (action.type) {
    case 'LEADS_FIND_MYLEADS': {
      if (action.data) {
        const myLeads = action.data.myLeads;
        let myGuardingLeads = [];
        let myEstimateLeads = [];
        for (var i = 0; i < myLeads.length; i++) {
          if (myLeads[i].type === 'guarding') {
            myGuardingLeads.push(myLeads[i]);
          } else {
            myEstimateLeads.push(myLeads[i]);
          }
        }
        return {
          ...state,
          myGuardingLeads: myGuardingLeads,
          myEstimateLeads: myEstimateLeads
        };
      }
      return state;
    }
    case 'LEADS_FIND_BASKETLEADS': {
      if (action.data) {
        const basketLeads = action.data.basketLeads;
        let basketLeadIds = [];
        basketLeads.forEach(lead => {
          basketLeadIds.push(lead._id);
        });
        return {
          ...state,
          basketLeads: basketLeads,
          basketLeadIds: basketLeadIds
        };
      }
      return initialState;
    }
    case 'LEADS_FIND_LEADDETAILS': {
      if (action.data) {
        const leadDetails = action.data.leadDetails;

        return {
          ...state,
          leadDetails: leadDetails
        };
      }
      return initialState;
    }
    case 'LEADS_FIND_AUCTIONLEADS': {
      if (action.data) {
        const auctionLeads = action.data.auctionLeads;
        let auctionLeadIds = [];
        auctionLeads.forEach(lead => {
          auctionLeadIds.push(lead._id);
        });
        return {
          ...state,
          auctionLeads: auctionLeads,
          auctionLeadIds: auctionLeadIds
        };
      }
      return initialState;
    }
    case 'LEADS_FIND_LEADDONELEADS': {
      if (action.data) {
        const leadDoneLeads = action.data.leadDoneLeads;
        return {
          ...state,
          leadDoneLeads: leadDoneLeads
        };
      }
      return initialState;
    }
    case 'LEADS_FIND_WONLEADS': {
      if (action.data) {
        const wonLeads = action.data.wonLeads;
        return {
          ...state,
          wonLeads: wonLeads
        };
      }
      return initialState;
    }
    case 'LEADS_FIND_LOSTLEADS': {
      if (action.data) {
        const lostLeads = action.data.lostLeads;
        return {
          ...state,
          lostLeads: lostLeads
        };
      }
      return initialState;
    }
    case 'LEADS_SET_EDITLEAD': {
      if (action.data) {
        const editLead = action.data;

        return {
          ...state,
          editLead: editLead
        };
      }
      return initialState;
    }
    case 'LEADS_SET_LEADDETAILSID': {
      if (action.data) {
        const id = action.data;
        return {
          ...state,
          leadDetailsId: id
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_LIST': {
      if (action.data) {
        let auctionLeads = state.auctionLeads;
        let updatedLeads = action.data.leads;
        auctionLeads.forEach(obj => {
          obj.highestBid = updatedLeads.find(o => o._id === obj._id).highestBid;
          obj.addAuctionMinutes = updatedLeads.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedLeads.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          auctionLeads: auctionLeads,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_BASKET': {
      if (action.data) {
        let basketLeads = state.basketLeads;
        let updatedLeads = action.data.leads;
        basketLeads.forEach(obj => {
          obj.highestBid = updatedLeads.find(o => o._id === obj._id).highestBid;
          obj.addAuctionMinutes = updatedLeads.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedLeads.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          basketLeads: basketLeads,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_SINGLE': {
      if (action.data) {
        let leadDetails = state.leadDetails || {};
        leadDetails.highestBid = action.data.lead.highestBid;
        leadDetails.addAuctionMinutes = action.data.lead.addAuctionMinutes;
        const timeStamp = action.data.timeStamp;

        return {
          ...state,
          leadDetails: leadDetails,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
