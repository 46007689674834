const pjson = require('../package.json');

const createReturnUrl = (
  type: 'deals' | 'leads',
  status: 'cancelled' | 'completed'
) => {
  const origin = window.location.origin;
  return `${origin}/branch/${type}?${status}`;
};

export default {
  API: process.env.REACT_APP_API_ENDPOINT,
  APP_VERSION: `PROD v${pjson.version}`,
  MERCHANT_ID: process.env.REACT_APP_MERCHANT_ID || '15178345',
  MERCHANT_KEY: process.env.REACT_APP_MERCHANT_KEY || '3j7kmwmnqkiho',
  DEAL_RETURN_URL: createReturnUrl('deals', 'completed'),
  DEAL_CANCEL_URL: createReturnUrl('deals', 'cancelled'),
  LEAD_RETURN_URL: createReturnUrl('leads', 'completed'),
  LEAD_CANCEL_URL: createReturnUrl('leads', 'cancelled'),
  PAYFAST_URL: process.env.REACT_APP_PAYFAST_URL
};
