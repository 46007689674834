import { Cookies } from 'react-cookie';
import config from 'src/config';
const getToken = () => {
  const cookies = new Cookies();
  return cookies.get('token');
};
export function signUpUser(values) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return fetch(`${config.API}/user/registeruser`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(values)
      })
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          }
          res.text();
        })
        .catch(error => {
          return reject(error);
        })
        .then(response => {
          try {
            return resolve({ message: 'Successfully registered account' });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function signUpDealer(values) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return fetch(`${config.API}/user/registerdealer`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(values)
      })
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          }
          res.text();
        })
        .catch(error => {
          return reject(error);
        })
        .then(response => {
          try {
            return resolve({
              message: 'Successfully registered account'
            });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function signIn(values) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      // Validation checks
      if (!values.username) return reject({ message: 'Email required' });
      if (!values.password) return reject({ message: 'Password required' });
      return fetch(`${config.API}/user/signin`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(values)
      })
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          }
          return resolve(
            dispatch({
              type: 'USER_LOGIN',
              data: await res.json()
            })
          );
        })
        .catch(async error => {
          return reject(error);
        });
    }).catch(async err => {
      throw err.message;
    });
}

export function saveAccountInfo(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/user/updateuser`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(values)
      })
        .then(res => res.text())
        .catch(error => {
          return reject(error);
        })
        .then(response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'USER_UPDATE',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}
export function changePassword(values, token) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/user/changepassword`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: token ? 'Bearer ' + token : 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(values)
      })
        .then(res => res.text())
        .catch(error => {
          return reject(error);
        })
        .then(response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'USER_LOGIN',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}
