import { Cookies } from 'react-cookie';
import config from 'src/config';
const getToken = () => {
  const cookies = new Cookies();
  return cookies.get('token');
};
export function sendNotification(
  id,
  channels,
  message,
  type,
  dealId,
  email,
  subject
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/notifications/postnotification`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          id: id,
          channels: channels,
          message: message,
          type: type,
          dealId: dealId,
          email: email,
          subject: subject
        })
      })
        .then(res => {
          if (!res.ok) {
            return reject({ message: 'Failed to send notification' });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function removeNotification(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/notifications/removenotification`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to add deal' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findNotifications() {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(`${config.API}/notifications/getnotifications`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'NOTIFICATIONS_FIND',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}
