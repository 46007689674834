import { createSelector } from 'reselect';
import { DEFAULT_LOCALE } from 'src/i18n/i18n';
export { getToken } from 'src/redux/actions/admin';

export const getLocale = () => DEFAULT_LOCALE; // TODO locale from state

const user = state => state.user.user;
export const getUser = createSelector(
  user,
  user => user
);
