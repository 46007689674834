import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  completeLead,
  findMyLeads,
  payLead,
  updateFollowUp
} from 'src/redux/actions/leads';
import {
  completeDeal,
  findMyDeals,
  payDeal,
  updateFollowUpDeal
} from 'src/redux/actions/deals';

class Leads extends Component {
  render = () => {
    const {
      Layout,
      findMyLeads,
      findMyDeals,
      payLead,
      payDeal,
      completeLead,
      completeDeal,
      updateFollowUp,
      updateFollowUpDeal,
      guardingLeads,
      estimateLeads,
      deals,
      push
    } = this.props;
    return (
      <Layout
        findMyLeads={findMyLeads}
        findMyDeals={findMyDeals}
        payLead={payLead}
        payDeal={payDeal}
        completeLead={completeLead}
        completeDeal={completeDeal}
        updateFollowUp={updateFollowUp}
        updateFollowUpDeal={updateFollowUpDeal}
        guardingLeads={guardingLeads}
        estimateLeads={estimateLeads}
        deals={deals}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  guardingLeads: state.leads.myGuardingLeads || [],
  estimateLeads: state.leads.myEstimateLeads || [],
  deals: state.deals.myDeals || []
});

const mapDispatchToProps = {
  findMyLeads,
  findMyDeals,
  payLead,
  payDeal,
  updateFollowUp,
  updateFollowUpDeal,
  completeLead,
  completeDeal,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Leads);
