import { Col, Form, Input, Modal, Row, Spin } from 'antd';
import React from 'react';
import { ButtonRadius } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
import config from 'src/config';
import styled from 'styled-components';

const FormItem = Form.Item;
const Inputs = styled(Input)`
  .ant-input:focus {
    border-bottom: 2px solid rgba(0, 136, 142, 0.2);
  }
`;

class SignIn extends React.Component {
  static defaultProps = {
    error: null,
    member: {}
  };
  componentWillMount = () => {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('reset')) {
      this.setState({
        resetPassVisible: true,
        token: searchParams.get('token')
      });
    }
  };
  handleSubmitUser = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err || (!err.username && !err.password)) {
        this.signIn(values);
      }
    });
  };

  forgotPassword = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err || !err.username) {
        fetch(`${config.API}/user/resetrequest`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(values)
        })
          .then(async res => {
            if (!res.ok) {
              Notify('error', 'Failed', await res.text());
            } else {
              this.setState({ resetVisible: false });
              Notify('success', 'Success', await res.text());
            }
          })
          .catch(async error => {
            Notify('error', 'Failed', error);
          });
      }
    });
  };

  resetPassword = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (this.state.resetPassVisible) {
        if (values.newPass && values.confirmPass) {
          this.setState({ loading: true });
          this.props
            .changePassword(values, this.state.token)
            .then(() => {
              this.setState({ loading: false, resetPassVisible: false });
              Notify('success', 'Success', 'Successfully changed password');
            })
            .catch(e => {
              this.setState({ loading: false });
              Notify('error', window.i18n.translate('Notify3'), e);
            });
        }
      } else if (!err) {
        this.setState({ loading: true });
        this.props
          .changePassword(values, this.state.token)
          .then(() => {
            this.setState({ loading: false, resetPassVisible: false });
            Notify('success', 'Success', 'Successfully changed password');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', window.i18n.translate('Notify3'), e);
          });
      }
    });
  };

  signIn = values => {
    this.setState({ loading: true });
    this.props
      .signIn(values)
      .then(() => {
        if (this.props.user.firstLogin) {
          this.setState({ loading: false, resetPassVisible: true });
        } else {
          this.setState({ loading: false });
          if (this.props.user.type === 'admin') {
            this.props.push('/' + this.props.user.type + '/companies');
          } else {
            this.props.push('/' + this.props.user.type + '/leads');
          }
        }
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', 'Failed', e);
      });
  };

  toggleShowPass = () => {
    this.state.passShow === 'password'
      ? this.setState({ passShow: 'text' })
      : this.setState({ passShow: 'password' });
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      passShow: 'password',
      resetVisible: false,
      resetPassVisible: false
    };
  }

  handleLocaleSwitch = locale => {
    this.setState({ loading: true });
    this.props
      .changeLocale(locale)
      .then(() => {
        this.props.form.resetFields();
        this.setState({ loading: false });
        // location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPass')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPass'], { force: true });
    }
    callback();
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{ background: 'white' }}>
        <Row
          style={{ height: '100vh' }}
          type="flex"
          justify="space-around"
          align="middle"
        >
          <Col offset={5} span={7} pull={2}>
            <h1 style={{ fontSize: '50px', color: '#808285' }}>
              Sign in on SecurAll
            </h1>
            <Spin spinning={this.state.loading}>
              <Form onSubmit={this.handleSubmitUser}>
                <FormItem
                  label={
                    <span style={{ color: '#00B4BC', fontSize: 20 }}>
                      Username
                    </span>
                  }
                >
                  {getFieldDecorator('username', {
                    rules: [{ required: true, message: 'Username required.' }]
                  })(
                    <Inputs
                      style={{
                        width: '100%',
                        border: 0,
                        borderRadius: 0,
                        borderBottom: '1px solid rgba(189, 178, 178, 0.43)'
                      }}
                    />
                  )}
                </FormItem>
                <FormItem
                  style={{ marginBottom: 0 }}
                  label={
                    <span style={{ color: '#00B4BC', fontSize: 20 }}>
                      Password
                    </span>
                  }
                >
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Password required' }]
                  })(
                    <Inputs
                      type="password"
                      style={{
                        width: '100%',
                        border: 0,
                        borderRadius: 0,
                        borderBottom: '1px solid rgba(189, 178, 178, 0.43)'
                      }}
                    />
                  )}
                </FormItem>
                <FormItem>
                  <a onClick={e => this.setState({ resetVisible: true })}>
                    Forgot password?
                  </a>
                </FormItem>
                <FormItem>
                  <ButtonRadius
                    style={{ height: 80, fontSize: 30 }}
                    className="submit-button"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                  >
                    Sign In
                  </ButtonRadius>
                </FormItem>
              </Form>
            </Spin>
          </Col>
        </Row>
        <Modal
          title={'Reset Password'}
          visible={this.state.resetVisible}
          onOk={this.forgotPassword}
          onCancel={e => this.setState({ resetVisible: false })}
          okText="Reset"
          cancelText="Cancel"
        >
          <Form>
            <FormItem
              label={
                <span style={{ color: '#00B4BC', fontSize: 20 }}>Username</span>
              }
            >
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Username required.' }]
              })(<Inputs />)}
            </FormItem>
          </Form>
        </Modal>
        <Modal
          title={'Reset Password'}
          visible={this.state.resetPassVisible}
          onOk={this.resetPassword}
          onCancel={e => this.setState({ resetPassVisible: false })}
          okText="Reset"
          cancelText="Cancel"
        >
          <Form>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={11}>
                <FormItem
                  label={
                    <span style={{ color: '#6D6E70', fontSize: 16 }}>
                      New Password
                    </span>
                  }
                  hasFeedback
                >
                  {this.props.form.getFieldDecorator('newPass', {
                    rules: [
                      { required: true, message: 'New password required' },
                      { validator: this.validateToNextPassword }
                    ]
                  })(
                    <Input.Password
                      onBlur={this.handleConfirmBlur}
                      type="password"
                      style={{ borderColor: '#BDB2B2', height: 45 }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={11}>
                <FormItem
                  label={
                    <span style={{ color: '#6D6E70', fontSize: 16 }}>
                      Confirm New Password
                    </span>
                  }
                  hasFeedback
                >
                  {this.props.form.getFieldDecorator('confirmPass', {
                    rules: [
                      { required: true, message: 'Confirm new password' },
                      { validator: this.compareToFirstPassword }
                    ]
                  })(
                    <Input.Password
                      type="password"
                      style={{ borderColor: '#BDB2B2', height: 45 }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const WrappedSignIn = Form.create()(SignIn);

export default WrappedSignIn;
