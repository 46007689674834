import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Pagination
} from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import PlacesAutocomplete from 'react-places-autocomplete';
import Notify from 'src/components/meta/Notification';
const { Column } = Table;
const FormItem = Form.Item;
const Option = Select.Option;
const { TabPane } = Tabs;
const { Search } = Input;

class CompanyFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Company Name</span>}>
          {this.props.form.getFieldDecorator('name', {
            rules: [{ required: true, message: 'Company name required' }],
            initialValue: this.props.editCompany
              ? this.props.editCompany.name
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Contact Email</span>}>
          {this.props.form.getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Email required' },
              {
                type: 'email',
                message: 'A valid email is required.'
              }
            ],
            initialValue: this.props.editCompany
              ? this.props.editCompany.email
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Contact Number</span>}>
          {this.props.form.getFieldDecorator('number', {
            rules: [{ required: true, message: 'Number required' }],
            initialValue: this.props.editCompany
              ? this.props.editCompany.number
              : ''
          })(<Input maxLength={10} />)}
        </FormItem>
        <FormItem label={<span>VAT Number</span>}>
          {this.props.form.getFieldDecorator('vat', {
            initialValue: this.props.editCompany
              ? this.props.editCompany.vat
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Company Registration Number</span>}>
          {this.props.form.getFieldDecorator('reg', {
            rules: [
              {
                required: true,
                message: 'Company registration number required'
              }
            ],
            initialValue: this.props.editCompany
              ? this.props.editCompany.reg
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Street Address</span>}>
          {this.props.form.getFieldDecorator('address1', {
            rules: [{ required: true, message: 'Address required' }],
            initialValue: this.props.editCompany
              ? this.props.editCompany.address1
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Suburb</span>}>
          {this.props.form.getFieldDecorator('address2', {
            rules: [{ required: true, message: 'Address required' }],
            initialValue: this.props.editCompany
              ? this.props.editCompany.address2
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>City / Town</span>}>
          {this.props.form.getFieldDecorator('address3', {
            rules: [{ required: true, message: 'Address required' }],
            initialValue: this.props.editCompany
              ? this.props.editCompany.address3
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Postal Code</span>}>
          {this.props.form.getFieldDecorator('address4', {
            rules: [{ required: true, message: 'Address required' }],
            initialValue: this.props.editCompany
              ? this.props.editCompany.address4
              : ''
          })(<Input />)}
        </FormItem>
      </Form>
    );
  }
}
const CompanyForm = Form.create()(CompanyFormClass);
class BranchFormClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }
  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    // let areas = this.state.areas;
    // areas.push(address)
    // this.setState({ areas: areas })
    // this.props.form.setFieldsValue({
    //   areas: this.state.areas
    // });
    // geocodeByAddress(address)
    //   .then(results => getLatLng(results[0]))
    //   .then(latLng => console.log('Success', latLng))
    //   .catch(error => console.error('Error', error));
  };
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Company</span>}>
          {this.props.editBranch ? (
            <Input disabled={true} value={this.props.editBranch.orgName} />
          ) : (
            this.props.form.getFieldDecorator('orgId', {
              rules: [{ required: true, message: 'Company required' }]
            })(
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select company"
                optionFilterProp="children"
              >
                {this.props.companies.map(company => {
                  return (
                    <Option key={company._id} value={company._id}>
                      {company.name}
                    </Option>
                  );
                })}
              </Select>
            )
          )}
        </FormItem>
        <FormItem label={<span>Username</span>}>
          {this.props.form.getFieldDecorator('username', {
            rules: [{ required: true, message: 'Username required' }],
            initialValue: this.props.editBranch
              ? this.props.editBranch.username
              : ''
          })(<Input disabled={this.props.editBranch ? true : false} />)}
        </FormItem>
        <FormItem label={<span>Branch Name</span>}>
          {this.props.form.getFieldDecorator('name', {
            rules: [{ required: true, message: 'Branch name required' }],
            initialValue: this.props.editBranch
              ? this.props.editBranch.name
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Email</span>}>
          {this.props.form.getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Email required' },
              {
                type: 'email',
                message: 'A valid email is required.'
              }
            ],
            initialValue: this.props.editBranch
              ? this.props.editBranch.email
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Number</span>}>
          {this.props.form.getFieldDecorator('number', {
            rules: [{ required: true, message: 'Number required' }],
            initialValue: this.props.editBranch
              ? this.props.editBranch.number
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>ID Prefix</span>}>
          {this.props.form.getFieldDecorator('idPrefix', {
            rules: [{ required: true, message: 'ID prefix required' }],
            initialValue: this.props.editBranch
              ? this.props.editBranch.idPrefix
              : ''
          })(<Input />)}
        </FormItem>

        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={{
            componentRestrictions: { country: 'za' },
            types: ['(regions)']
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => {
            return (
              <FormItem label={<span>Areas Covered</span>}>
                {this.props.form.getFieldDecorator('areas', {
                  rules: [{ required: true, message: 'Areas required' }],
                  initialValue: this.props.editBranch
                    ? this.props.editBranch.areas
                    : []
                })(
                  <Select
                    mode="multiple"
                    showSearch={false}
                    style={{ width: '100%' }}
                    placeholder="Search areas"
                    optionFilterProp="children"
                    onSearch={e => {
                      let event = {
                        target: {
                          value: e
                        }
                      };
                      getInputProps().onChange(event);
                    }}
                  >
                    {suggestions.map(suggestion => {
                      return (
                        <Option
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.id}
                          value={
                            suggestion.description.split(',')[1]
                              ? suggestion.description.split(',')[0]
                              : suggestion.description
                          }
                        >
                          {suggestion.description.split(',')[1]
                            ? suggestion.description.split(',')[0]
                            : suggestion.description}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
            );
          }}
        </PlacesAutocomplete>
      </Form>
    );
  }
}
const BranchForm = Form.create()(BranchFormClass);
class Companies extends React.Component {
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllCompanies(1)
      .then(() => {
        this.props
          .findAllBranches(1)
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: '',
      companyPagination: 1,
      branchPagination: 1,
      companySearch: '',
      branchSearch: ''
    };
  }

  stopProp = e => {
    e.stopPropagation();
  };

  handleSelectCompany = e => {
    this.props.setCompanyDetailsId(e.currentTarget.getAttribute('data'));
    this.props.push('/admin/companydetails');
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let companies = this.props.companies;
    if (order === 'ascend') {
      companies.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      companies.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedInfo: sorter
    });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleDeleteCompany = e => {
    const id = e.currentTarget.getAttribute('data');
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>
            Are your sure you want to delete this company and all of their
            branches?
          </h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteCompany(id)
          .then(() => {
            that.props
              .findAllCompanies(1)
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully deleted company');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };
  handleActivateBranch = (id, active) => {
    this.setState({ loading: true });
    this.props
      .activateBranch(id, active)
      .then(() => {
        this.props
          .findAllBranches(1)
          .then(() => {
            this.setState({ loading: false });
            Notify('success', 'Successfully changed branch status');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  onAddCompany = e => {
    this.setState({ addCompanyVisible: true, editCompany: undefined });
  };
  handleAddCompany = e => {
    e.preventDefault();
    this.companyForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editCompany) {
          values._id = this.state.editCompany._id;
        }
        this.props
          .addCompany(values)
          .then(() => {
            this.props
              .findAllCompanies(1)
              .then(() => {
                if (this.state.editCompany) {
                  Notify('success', 'Successfully saved company');
                } else {
                  Notify('success', 'Successfully added company');
                }
                this.companyForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addCompanyVisible: false,
                  editCompany: undefined
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };
  handleEditCompany = e => {
    this.setState({ addCompanyVisible: true, editCompany: e });
  };
  handleDeleteCompany = e => {
    const id = e.currentTarget.getAttribute('data');
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>
            Are your sure you want to delete this company and all of their
            branches?
          </h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteCompany(id)
          .then(() => {
            that.props
              .findAllCompanies(1)
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully deleted company');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };
  onAddBranch = e => {
    this.setState({ addBranchVisible: true, editBranch: undefined });
  };

  handleRefresh = async e => {
    this.setState({ loading: true });
    await this.props.findAllBranches(1).then(res => console.log('Fetched'));
    await this.props
      .findAllCompanies(1)
      .then(res => this.setState({ loading: false }));
  };

  handleAddBranch = e => {
    e.preventDefault();
    this.branchForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editBranch) {
          values._id = this.state.editBranch._id;
        }
        values.type = 'branch';
        this.props
          .addBranch(values)
          .then(() => {
            if (this.state.editBranch) {
              Notify('success', 'Successfully saved branch');
            } else {
              Notify('success', 'Successfully added branch');
            }
            this.branchForm.props.form.resetFields();
            this.setState({
              loading: false,
              addBranchVisible: false,
              editBranch: undefined
            });
            this.handleRefresh();
            // this.props
            //   .findAllBranches()
            //   .then(() => {
            //     this.props
            //       .findAllCompanies()
            //       .then(() => {
            //         if (this.state.editBranch) {
            //           Notify('success', 'Successfully saved branch');
            //         } else {
            //           Notify('success', 'Successfully added branch');
            //         }
            //         this.branchForm.props.form.resetFields();
            //         this.setState({
            //           loading: false,
            //           addBranchVisible: false,
            //           editBranch: undefined
            //         });
            //       })
            //       .catch(e => {
            //         this.setState({ loading: false });
            //         Notify('error', e);
            //       });
            //   })
            //   .catch(e => {
            //     this.setState({ loading: false });
            //     Notify('error', e);
            //   });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };
  handleEditBranch = e => {
    this.setState({ addBranchVisible: true, editBranch: e });
  };
  handleDeleteBranch = e => {
    const id = e.currentTarget.getAttribute('data');
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are your sure you want to delete this branch?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteBranch(id)
          .then(() => {
            that.props
              .findAllBranches(1)
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully deleted branch');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };

  handleCompanyPaginationPageChange = val => {
    // Change Pagination State
    this.setState({
      ...this.state,
      companyPagination: val
    });

    // Fetch Companies based on page
    this.setState({ loading: true });
    this.props
      .findAllCompanies(val)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  handleBranchesPaginationPageChange = val => {
    // Change Pagination State
    this.setState({
      ...this.state,
      branchPagination: val
    });

    // Fetch Companies based on page
    this.setState({ loading: true });
    this.props
      .findAllBranches(val)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  onSearchCompaniesChange = e => {
    const { value } = e.target;
    this.setState({
      ...this.state,
      companySearch: value
    });
  };

  onSearchCompanes = val => {
    this.setState({ loading: true });
    this.props
      .findAllCompanies(1, val)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  onSearchBranchesChange = e => {
    const { value } = e.target;
    this.setState({
      ...this.state,
      branchSearch: value
    });
  };

  onSearchBranches = val => {
    this.setState({ loading: true });
    this.props
      .findAllBranches(1, val)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  onClearSearch = () => {
    this.setState({
      ...this.state,
      loading: true,
      companySearch: '',
      branchSearch: ''
    });
    this.props
      .findAllCompanies(1)
      .then(() => {
        this.props
          .findAllBranches(1)
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  render() {
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12}>
            <Icon
              style={{ fontSize: 25, color: 'rgb(4, 30, 66)', marginRight: 20 }}
              type="file-protect"
            />
            <span
              style={{ fontWeight: 750, fontSize: 25, color: 'rgb(4, 30, 66)' }}
            >
              Manage Companies
            </span>
          </Col>
          <Col span={12}>
            <Button
              onClick={this.onAddBranch}
              type="primary"
              style={{ float: 'right' }}
            >
              Add Branch
            </Button>
            <Button
              onClick={this.onAddCompany}
              type="primary"
              style={{ float: 'right', marginRight: 15 }}
            >
              Add Company
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Tabs
            style={{ overflow: 'visible' }}
            // onChange={this.handleTabChange}
          >
            <TabPane tab="Companies" key="1">
              <Row type="flex" justify="start" align="middle">
                <div
                  style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}
                >
                  <Search
                    placeholder="Search Companies"
                    onSearch={this.onSearchCompanes}
                    style={{ width: 200 }}
                    value={this.state.companySearch}
                    onChange={this.onSearchCompaniesChange}
                  />
                  <button
                    onClick={this.onClearSearch}
                    style={{ width: '70px', cursor: 'pointer' }}
                  >
                    Clear
                  </button>
                </div>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <Table
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    pagination={false}
                    dataSource={this.props.companies}
                    onChange={this.handleSort}
                  >
                    <Column
                      {...this.getColumnSearchProps('_id')}
                      title="ID"
                      dataIndex="_id"
                      key="_id"
                    />
                    <Column
                      {...this.getColumnSearchProps('name')}
                      title="Company Name"
                      dataIndex="name"
                      key="name"
                    />
                    <Column
                      {...this.getColumnSearchProps('email')}
                      title="Email"
                      dataIndex="email"
                      key="email"
                    />
                    <Column
                      {...this.getColumnSearchProps('number')}
                      title="Number"
                      dataIndex="number"
                      key="number"
                    />
                    <Column
                      title="Branches"
                      render={(text, company) => (
                        <span>{company.branches}</span>
                      )}
                    />
                    <Column
                      title="Actions"
                      render={(text, company) => (
                        <span>
                          <a onClick={e => this.handleEditCompany(company)}>
                            Edit
                          </a>
                          <Divider type="vertical" />
                          <a
                            data={company._id}
                            onClick={this.handleDeleteCompany}
                          >
                            Delete
                          </a>
                        </span>
                      )}
                    />
                  </Table>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Pagination
                  onChange={this.handleCompanyPaginationPageChange}
                  defaultCurrent={1}
                  current={this.state.companyPagination}
                  pageSize={10}
                  total={this.props.totalCompanies}
                />
              </Row>
            </TabPane>
            <TabPane tab="Branches" key="2">
              <Row type="flex" justify="start" align="middle">
                <div
                  style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}
                >
                  <Search
                    placeholder="Search Branches"
                    onSearch={this.onSearchBranches}
                    style={{ width: 200 }}
                    value={this.state.branchSearch}
                    onChange={this.onSearchBranchesChange}
                  />
                  <button
                    onClick={this.onClearSearch}
                    style={{ width: '70px', cursor: 'pointer' }}
                  >
                    Clear
                  </button>
                </div>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <Table
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    pagination={false}
                    dataSource={this.props.branches}
                    onChange={this.handleSort}
                    onRow={(record, rowIndex) => {
                      if (!record.active) {
                        return {
                          style: { background: '#f9000042' }
                        };
                      }
                    }}
                  >
                    <Column
                      {...this.getColumnSearchProps('orgId')}
                      title="Company ID"
                      dataIndex="orgId"
                      key="orgId"
                    />
                    <Column
                      {...this.getColumnSearchProps('orgName')}
                      title="Company Name"
                      dataIndex="orgName"
                      key="orgName"
                    />
                    <Column
                      {...this.getColumnSearchProps('username')}
                      title="Username"
                      dataIndex="username"
                      key="username"
                    />
                    <Column
                      {...this.getColumnSearchProps('name')}
                      title="Name"
                      dataIndex="name"
                      key="name"
                    />
                    <Column
                      {...this.getColumnSearchProps('email')}
                      title="Email"
                      dataIndex="email"
                      key="email"
                    />
                    <Column
                      {...this.getColumnSearchProps('number')}
                      title="Number"
                      dataIndex="number"
                      key="number"
                    />
                    <Column
                      title="Covered Areas"
                      render={(text, branch) =>
                        branch.areas.map(area => {
                          return <span key={area}>{area}; </span>;
                        })
                      }
                    />
                    <Column
                      {...this.getColumnSearchProps('active')}
                      title="Active"
                      dataIndex="active"
                      key="active"
                      render={(text, branch) => (
                        <span key={branch._id}>
                          {branch.active ? 'True' : 'False'}
                        </span>
                      )}
                    />
                    <Column
                      title="Actions"
                      render={(text, branch) => {
                        if (this.props.user.isSuperAdmin) {
                          return (
                            <span>
                              <a onClick={e => this.handleEditBranch(branch)}>
                                Edit
                              </a>
                              <Divider type="vertical" />
                              <a
                                data={branch._id}
                                onClick={this.handleDeleteBranch}
                              >
                                Delete
                              </a>
                              <Divider type="vertical" />
                              <a
                                onClick={e =>
                                  this.handleActivateBranch(
                                    branch._id,
                                    !branch.active
                                  )
                                }
                              >
                                {branch.active ? 'Deactivate' : 'Activate'}
                              </a>
                            </span>
                          );
                        } else {
                          return (
                            <span>
                              <a onClick={e => this.handleEditBranch(branch)}>
                                Edit
                              </a>
                              <Divider type="vertical" />
                              <a
                                data={branch._id}
                                onClick={this.handleDeleteBranch}
                              >
                                Delete
                              </a>
                            </span>
                          );
                        }
                      }}
                    />
                  </Table>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Pagination
                  onChange={this.handleBranchesPaginationPageChange}
                  defaultCurrent={1}
                  current={this.state.branchPagination}
                  pageSize={10}
                  total={this.props.totalBranches}
                />
              </Row>
            </TabPane>
          </Tabs>
          <Modal
            title="Add Company"
            visible={this.state.addCompanyVisible}
            onOk={this.handleAddCompany}
            okButtonProps={{ loading: this.state.loading }}
            cancelButtonProps={{ loading: this.state.loading }}
            onCancel={e => this.setState({ addCompanyVisible: false })}
            okText={this.state.editCompany ? 'Save' : 'Add Company'}
            cancelText="Cancel"
          >
            <CompanyForm
              editCompany={this.state.editCompany}
              wrappedComponentRef={form => (this.companyForm = form)}
            />
          </Modal>
          <Modal
            style={{ top: 10 }}
            title="Add Branch"
            visible={this.state.addBranchVisible}
            onOk={this.handleAddBranch}
            okButtonProps={{ loading: this.state.loading }}
            cancelButtonProps={{ loading: this.state.loading }}
            onCancel={e => this.setState({ addBranchVisible: false })}
            okText={this.state.editBranch ? 'Save' : 'Add Branch'}
            cancelText="Cancel"
          >
            <BranchForm
              editBranch={this.state.editBranch}
              companies={this.props.companies}
              wrappedComponentRef={form => (this.branchForm = form)}
            />
          </Modal>
        </Spin>
      </div>
    );
  }
}

export default Companies;
