import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { saveAccountInfo, changePassword } from 'src/redux/actions/user';

class AccountSettings extends Component {
  render = () => {
    const {
      Layout,
      user,
      push,
      saveAccountInfo,
      changePassword
    } = this.props;
    return (
      <Layout
        user={user}
        push={push}
        saveAccountInfo={saveAccountInfo}
        changePassword={changePassword}
      />
    );
  };
}

const mapStateToProps = state => ({
  user: state.user.user
});

const mapDispatchToProps = {
  push,
  saveAccountInfo,
  changePassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettings);
