import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assignToUser, findAllUsers } from 'src/redux/actions/admin';
import {
  completeDeal,
  findMyDeals,
  invoiceDeal,
  updateFollowUpDeal
} from 'src/redux/actions/deals';

class Deals extends Component {
  render = () => {
    const {
      Layout,
      findMyDeals,
      invoiceDeal,
      completeDeal,
      updateFollowUp,
      findAllUsers,
      assignToUser,
      deals,
      totalDeals,
      users,
      user,
      push
    } = this.props;
    return (
      <Layout
        findMyDeals={findMyDeals}
        invoiceDeal={invoiceDeal}
        completeDeal={completeDeal}
        updateFollowUp={updateFollowUp}
        findAllUsers={findAllUsers}
        assignToUser={assignToUser}
        deals={deals}
        totalDeals={totalDeals}
        users={users}
        user={user}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  deals: state.deals.myDeals || [],
  users: state.admin.allUsers || [],
  user: state.user.user,
  totalDeals: state.deals.totalDeals
});

const mapDispatchToProps = {
  findMyDeals,
  invoiceDeal,
  updateFollowUpDeal,
  completeDeal,
  findAllUsers,
  assignToUser,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Deals);
