import React from 'react';
import styled from 'styled-components';
import {
  Icon,
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  DatePicker,
  Select,
  Form,
  Pagination
} from 'antd';
import moment from 'moment';
import Notify from 'src/components/meta/Notification';
import Countdown from 'react-countdown-now';
import Highlighter from 'react-highlight-words';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import config from 'src/config';
import api from 'src/lib/api';
import Devide from 'src/components/devide';
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item;
const numeral = require('numeral');
const { TabPane } = Tabs;

const StyledTable = styled(Table)`
  // .ant-table-thead > tr > th {
  //   text-align: center !important;

  // }
  .ant-table-thead > tr > th {
    line-height: 1em;
    font-size: 16px;
    color: grey;

    border-bottom: 1px solid#00878e34;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
    font-weight: 700;
    font-size: 11px;
  }
`;

class Deals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      rejectReason: '',
      dealsPagination: 1
    };
  }
  componentWillMount = () => {
    this.setState({ loading: true });
    var search = new URLSearchParams(window.location.search);
    this.props
      .findMyDeals()
      .then(() => {
        this.setState({ loading: false });
        if (search.has('completed')) {
          window.location.search = '';
          Modal.success({
            centered: true,
            content: (
              <div>
                <h4>Your payment was successfull, the deal is unlocked.</h4>
              </div>
            )
          });
        }
        if (search.has('cancelled')) {
          window.location.search = '';
          Modal.error({
            centered: true,
            content: (
              <div>
                <h4>Your payment was un-successfull please try again.</h4>
              </div>
            )
          });
        }
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  parseString = string => {
    return parseInt(string.split('R')[1].replace(/,/g, ''));
  };
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };

  handleSelectDeal = e => {
    this.props.setDealDetailsId(e.currentTarget.getAttribute('data'));
    this.props.setFromHistory(true);
    this.props.push('/admin/dealdetails');
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let deals = this.props.deals;
    if (order === 'ascend') {
      deals.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      deals.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  handleUpdateFollowup = e => {
    var that = this;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
    Modal.confirm({
      centered: true,
      width: 500,
      icon: null,
      content: (
        <div>
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <FormItem label={<span>Follow Up Result</span>}>
              <Select
                showSearch
                onChange={e => this.setState({ status: e })}
                style={{ width: '100%' }}
                placeholder="Select follow up result"
                optionFilterProp="children"
              >
                <Option
                  key="Installation Scheduled"
                  value="Installation Scheduled"
                >
                  Installation Scheduled
                </Option>
                <Option key="Installation Done" value="Installation Done">
                  Installation Done
                </Option>
                <Option key="Signed Off" value="Signed Off">
                  Signed Off
                </Option>
              </Select>
            </FormItem>
            <FormItem label={<span>Technician Name</span>}>
              <Input
                onChange={e =>
                  this.setState({ statusTech: e.currentTarget.value })
                }
              />
            </FormItem>
            <FormItem label={<span>Result Date</span>}>
              <DatePicker
                showTime
                placeholder={'Select date / time'}
                style={{ width: '100%' }}
                onChange={e => this.setState({ statusDate: e })}
              />
            </FormItem>
          </Form>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .updateFollowUpDeal(e._id, that.state)
          .then(() => {
            that.props
              .findMyDeals()
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Updated follow up');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleDownloadInvoice = deal => {
    this.setState({ loading: true });
    try {
      api.getDocument('dealinvoices', deal.invoice, true);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };
  handleDownloadDocument = deal => {
    this.setState({ loading: true });
    try {
      api.getDocument('dealforms', deal.contract, true);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };
  handleInvoiceDeal = deal => {
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>You will be redirected to Payfast for payment processing.</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .payDeal(deal._id)
          .then(() => {
            // that.props
            //   .findMyDeals()
            //   .then(() => {
            //     that.setState({ loading: false });
            //     Notify('success', 'Successfully unlocked deal');
            //   })
            //   .catch(e => {
            //     that.setState({ loading: false });
            //     Notify('error', e);
            //   });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };
  handleCompleteDeal = e => {
    this.setState({ loading: true });
    this.props
      .completeDeal(e._id)
      .then(() => {
        this.props
          .findMyDeals()
          .then(() => {
            this.setState({ loading: false });
            Notify('success', 'Successfully completed deal');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  handleDealsPaginationPageChange = val => {
    // Change Pagination State
    this.setState({
      ...this.state,
      dealsPagination: val
    });

    // Fetch Deals based on page
    this.setState({ loading: true });
    this.props
      .findMyDeals(undefined, val)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  render() {
    const { user } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12}>
            <Icon
              style={{ fontSize: 25, color: 'rgb(4, 30, 66)', marginRight: 20 }}
              type="dollar"
            />
            <span
              style={{ fontWeight: 750, fontSize: 25, color: 'rgb(4, 30, 66)' }}
            >
              Closed Deals
            </span>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <Table
                style={{ background: 'white' }}
                rowKey="_id"
                size="middle"
                pagination={false}
                dataSource={this.props.deals}
                onChange={this.handleSort}
              >
                <Column
                  {...this.getColumnSearchProps('displayId')}
                  title="ID"
                  dataIndex="displayId"
                  key="displayId"
                />
                <Column
                  title="Type"
                  dataIndex="type"
                  key="type"
                  filters={[
                    {
                      text: 'Linkup',
                      value: 'linkups'
                    },
                    {
                      text: 'Special',
                      value: 'specials'
                    }
                  ]}
                  filteredValue={filteredInfo.type || null}
                  onFilter={(value, record) => record.type.includes(value)}
                  render={(text, deal) => {
                    return (
                      <span>{text === 'linkups' ? 'Linkup' : 'Special'}</span>
                    );
                  }}
                />
                <Column
                  title="Client Type"
                  dataIndex="clientType"
                  key="clientType"
                  filters={[
                    {
                      text: 'Business',
                      value: 'Business'
                    },
                    {
                      text: 'Residential',
                      value: 'Residential'
                    }
                  ]}
                  filteredValue={filteredInfo.clientType || null}
                  onFilter={(value, record) =>
                    record.clientType.includes(value)
                  }
                />
                <Column
                  {...this.getColumnSearchProps('companyName')}
                  title="Company Name"
                  dataIndex="companyName"
                  key="companyName"
                />
                <Column
                  {...this.getColumnSearchProps('clientName')}
                  title="Client Name"
                  dataIndex="clientName"
                  key="clientName"
                />
                <Column
                  {...this.getColumnSearchProps('clientSurname')}
                  title="Client Surname"
                  dataIndex="clientSurname"
                  key="clientSurname"
                />
                <Column
                  {...this.getColumnSearchProps('clientNumber')}
                  title="Client Number"
                  dataIndex="clientNumber"
                  key="clientNumber"
                />
                <Column
                  {...this.getColumnSearchProps('clientEmail')}
                  title="Client Email"
                  dataIndex="clientEmail"
                  key="clientEmail"
                />
                <Column
                  {...this.getColumnSearchProps('clientAddress')}
                  title="Client Address"
                  dataIndex="clientAddress"
                  key="clientAddress"
                />
                <Column
                  {...this.getColumnSearchProps('requestTimestamp')}
                  title="Request Date"
                  dataIndex="requestTimestamp"
                  key="requestTimestamp"
                />
                <Column
                  title="Status"
                  filters={[
                    {
                      text: 'Installation Scheduled',
                      value: 'Installation Scheduled'
                    },
                    {
                      text: 'Installation Done',
                      value: 'Installation Done'
                    },
                    {
                      text: 'Signed Off',
                      value: 'Signed Off'
                    }
                  ]}
                  filteredValue={filteredInfo.status || null}
                  onFilter={(value, record) => record.status.includes(value)}
                  dataIndex="status"
                  key="status"
                />
                <Column
                  sorter={() => {}}
                  title="Last Follow Up"
                  dataIndex="lastFollowUp"
                  key="lastFollowUp"
                  render={(text, deal) => (
                    <div>
                      {text ? (
                        <span
                          style={{
                            color:
                              moment().diff(moment(text), 'minutes') > 1440
                                ? 'red'
                                : 'green'
                          }}
                        >
                          {moment(text).format('YYYY-MM-DD HH:MM:ss')}
                        </span>
                      ) : (
                        <span style={{ color: 'red' }}>Never</span>
                      )}
                    </div>
                  )}
                />
                <Column
                  title="Actions"
                  render={(text, deal) => (
                    <div>
                      {!deal.invoiced ? (
                        <span>
                          <a onClick={e => this.handleInvoiceDeal(deal)}>
                            Unlock Deal
                          </a>
                        </span>
                      ) : (
                        <Devide direction="vertical">
                          <a onClick={e => this.handleUpdateFollowup(deal)}>
                            Follow Up
                          </a>
                          <a onClick={e => this.handleCompleteDeal(deal)}>
                            Complete Deal
                          </a>
                          {deal.contract && (
                            <a onClick={e => this.handleDownloadDocument(deal)}>
                              Download Contract
                            </a>
                          )}
                          {deal.invoice && (
                            <a onClick={e => this.handleDownloadInvoice(deal)}>
                              Download Invoice
                            </a>
                          )}
                        </Devide>
                      )}
                    </div>
                  )}
                />
              </Table>
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Pagination
              onChange={this.handleDealsPaginationPageChange}
              defaultCurrent={1}
              current={this.state.dealsPagination}
              pageSize={10}
              total={this.props.totalDeals}
            />
          </Row>
        </Spin>
      </div>
    );
  }
}

export default Deals;
