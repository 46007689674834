import user from './user';
import cddata from './cddata';
import deals from './deals';
import leads from './leads';
import admin from './admin';
import status from './status';
import notifications from './notifications';
import PubNub from 'pubnub';

const rehydrated = (state = false, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload) {
        if (action.payload.user) {
          if (action.payload.user.user) {
            window.pubnub = new PubNub({
              publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
              subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY
            });

            window.pubnub.setUUID(action.payload.user.user._id);
            let channels = [action.payload.user.user._id, 'global'];
            if (action.payload.user.user.type === 'admin') {
              channels.push('admin');
              channels.push('dealers');
            }
            if (action.payload.user.user.type === 'dealer') {
              channels.push('dealers');
            }
            window.pubnub.subscribe({
              channels: channels
            });
            if (window.cordova) {
              window.FirebasePlugin.getToken(
                token => {
                  window.pubnub.push.addChannels(
                    {
                      channels: channels,
                      device: token,
                      pushGateway: 'gcm' // apns, gcm, mpns
                    },
                    function(status) {
                      if (status.error) {
                        console.log('operation failed w/ error:', status);
                      }
                    }
                  );
                },
                error => {
                  console.error(error);
                }
              );
              window.FirebasePlugin.onNotificationOpen(
                notif => {
                  notif.message = JSON.parse(notif.message);
                  // this.props.setPubnub(notif)
                },
                error => {
                  console.error(error);
                }
              );
            }
          }
        }
      }
      return true;
    default:
      return state;
  }
};

export default {
  rehydrated,
  user,
  cddata,
  deals,
  leads,
  admin,
  status,
  notifications
};
