import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findAllCompanies,
  addCompany,
  deleteCompany,
  findAllBranches,
  addBranch,
  deleteBranch,
  activateBranch
} from 'src/redux/actions/admin';
import { sendNotification } from 'src/redux/actions/notifications';

class Companies extends Component {
  render = () => {
    const {
      Layout,
      companies,
      totalCompanies,
      branches,
      totalBranches,
      user,
      findAllCompanies,
      addCompany,
      deleteCompany,
      findAllBranches,
      addBranch,
      deleteBranch,
      activateBranch,
      sendNotification,
      push
    } = this.props;
    return (
      <Layout
        companies={companies}
        totalCompanies={totalCompanies}
        branches={branches}
        totalBranches={totalBranches}
        user={user}
        findAllCompanies={findAllCompanies}
        addCompany={addCompany}
        deleteCompany={deleteCompany}
        findAllBranches={findAllBranches}
        addBranch={addBranch}
        deleteBranch={deleteBranch}
        activateBranch={activateBranch}
        sendNotification={sendNotification}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  companies: state.admin.allCompanies || [],
  totalCompanies: state.admin.totalCompanies || 0,
  branches: state.admin.allBranches || [],
  totalBranches: state.admin.totalBranches || 0,
  user: state.user.user || {}
});

const mapDispatchToProps = {
  findAllCompanies,
  addCompany,
  deleteCompany,
  findAllBranches,
  addBranch,
  deleteBranch,
  activateBranch,
  sendNotification,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Companies);
