export const initialState = {
    sidebarCollapsed: true
}

export default function statusReducer(state = initialState, action) {
  switch (action.type) {
    case "SIDEBAR_COLLAPSE": {
      let partialState = {
          sidebarCollapsed: !state.sidebarCollapsed,
      }

      return {
        ...state,
        ...partialState,
      }
    }
    default:
      return state
  }
}
