import { Icon, Layout, Menu } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { collapseSidebar } from 'src/redux/actions/status';
import styled from 'styled-components';

const { Sider } = Layout;

const StyledLink = styled(Link)`
  color: #fff;
  :hover {
    text-decoration: none !important;
  }
  .anticon {
    color: #00888e;
  }
`;

const Sidebar = props => {
  return (
    <Sider
      width="250"
      // collapsedWidth='180'
      trigger={null}
      // collapsible
      // collapsed={false}
      id="dealerSidebarId"
      style={{
        // border: '1px solid #00888e3b',
        zIndex: 1,
        position: 'relative',
        background: 'white',
        height: 'calc(100vh - 60px)',
        left: 0,
        top: 60,
        width: '250px !important',
        maxWidth: '250px !important',
        minWidth: '250px !important'
      }}
    >
      <Menu
        style={{ background: 'none' }}
        theme="light"
        mode="inline"
        defaultSelectedKeys={['companies']}
      >
        {props.user.isSuperAdmin ? (
          <Menu.Item key="users">
            <StyledLink to="/admin/users">
              <Icon
                type="team"
                style={{
                  color: '#041E42',
                  fontSize: '20px',
                  paddingRight: '12px'
                }}
              />
              <span style={{ color: '#041E42', fontSize: '15px' }}>
                Manage Users
              </span>
            </StyledLink>
          </Menu.Item>
        ) : null}
        <Menu.Item key="companies">
          <StyledLink to="/admin/companies">
            <Icon
              type="file-protect"
              style={{
                color: '#041E42',
                fontSize: '20px',
                paddingRight: '12px'
              }}
            />
            <span style={{ color: '#041E42', fontSize: '15px' }}>
              Manage Companies
            </span>
          </StyledLink>
        </Menu.Item>
        {/* Remove Closed Deals From Admin Sidebar */}
        {/* <Menu.Item key="deals">
          <StyledLink to="/admin/deals">
            <Icon
              type="dollar"
              style={{
                color: '#041E42',
                fontSize: '20px',
                paddingRight: '12px'
              }}
            />
            <span style={{ color: '#041E42', fontSize: '15px' }}>
              Closed Deals
            </span>
          </StyledLink>
        </Menu.Item> */}
        <Menu.Item key="leads">
          <StyledLink to="/admin/leads">
            <Icon
              type="dollar"
              style={{
                color: '#041E42',
                fontSize: '20px',
                paddingRight: '12px'
              }}
            />
            <span style={{ color: '#041E42', fontSize: '15px' }}>Leads</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item key="history">
          <StyledLink to="/admin/history">
            <Icon
              type="history"
              style={{
                color: '#041E42',
                fontSize: '20px',
                paddingRight: '12px'
              }}
            />
            <span style={{ color: '#041E42', fontSize: '15px' }}>
              History Deals / Leads
            </span>
          </StyledLink>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

const mapStateToProps = state => {
  return {
    collapsed: state.status.collapseSidebar,
    user: state.user.user
  };
};

const mapDispatchToProps = {
  collapseSidebar
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
