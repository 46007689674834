import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
.inputs-group {
    svg {
      width: 25px;
      height: 25px;
    }
    border-radius: 0.5rem;
    box-shadow: 0px 0px 20px 8px rgba(0, 180, 188, 0.17);
    padding: 20px 50px;
  
    .ant-form-item {
      margin-bottom: 0;
      border-bottom: 1px solid #bdb2b2;
  
      &:last-child {
        margin-bottom: 2rem;
        border-bottom: none;
      }
  
      .ant-form-explain {
        padding-left: 1rem;
      }
      .ant-form-item-label {
        color: #00b4bc;
      }
    }
  
    span.ant-input-affix-wrapper {
      box-shadow: none;
    }
  
    textarea.ant-input,
    input.ant-input {
      box-shadow: none;
      border: none;
      border-radius: 0;
  
      &:first-of-type {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
  
      &:last-of-type {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
  }
  
  .ant-list-item-meta-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 15px !important;
  }
  
  .ant-layout-sider {
    position: relative;
    min-width: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  #dealerSidebarId:hover {
    max-width: 250px !important;
    min-width: 250px !important;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    height: 40px;
    width: 14.2vw;
    color: #ffffff !important;
    background: #00888e !important;
  }
  
  .ant-menu-item > a {
    margin-left: 5px;
    display: inline !important;
    font-weight: 500;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 0px solid rgba(107, 122, 122, 0.137) !important;
  }
`;

export default GlobalStyle;
