import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs
} from 'antd';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import Devide from 'src/components/devide';
import Notify from 'src/components/meta/Notification';
import config from 'src/config';
import api from 'src/lib/api';
const { Column } = Table;
const { Option } = Select;
const FormItem = Form.Item;
const numeral = require('numeral');
const { TabPane } = Tabs;

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      rejectReason: ''
    };
  }
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findMyLeads('closed')
      .then(() => {
        this.props
          .findMyDeals('closed')
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  parseString = string => {
    return parseInt(string.split('R')[1].replace(/,/g, ''));
  };
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };

  handleSelectLead = e => {
    this.props.setLeadDetailsId(e.currentTarget.getAttribute('data'));
    this.props.setFromHistory(true);
    this.props.push('/admin/leaddetails');
  };
  handleSortAlarm = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let leads = this.props.estimateLeads;
    if (order === 'ascend') {
      leads.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      leads.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  handleSortGuard = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let leads = this.props.guardingLeads;
    if (order === 'ascend') {
      leads.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      leads.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let leads = this.props.deals;
    if (order === 'ascend') {
      leads.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      leads.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  handleUpdateFollowup = e => {
    var that = this;

    Modal.confirm({
      centered: true,
      width: 500,
      content: (
        <div>
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <FormItem label={<span>Follow Up Result</span>}>
              <Select
                showSearch
                onChange={e => this.setState({ status: e })}
                style={{ width: '100%' }}
                placeholder="Select follow up result"
                optionFilterProp="children"
              >
                <Option
                  key="Installation Scheduled"
                  value="Installation Scheduled"
                >
                  Installation Scheduled
                </Option>
                <Option key="Installation Done" value="Installation Done">
                  Installation Done
                </Option>
                <Option key="Signed Off" value="Signed Off">
                  Signed Off
                </Option>
              </Select>
            </FormItem>
            <FormItem label={<span>Technician Name</span>}>
              <Input
                onChange={e =>
                  this.setState({ statusTech: e.currentTarget.value })
                }
              />
            </FormItem>
            <FormItem label={<span>Result Date</span>}>
              <DatePicker
                showTime
                placeholder={'Select date / time'}
                style={{ width: '100%' }}
                onChange={e => this.setState({ statusDate: e })}
              />
            </FormItem>
          </Form>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .updateFollowUp(e._id, that.state)
          .then(() => {
            that.props
              .findMyLeads()
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Updated follow up');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleDownloadInvoice = lead => {
    this.setState({ loading: true });
    try {
      api.getDocument('leadinvoices', lead.contract, true);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };
  handleDownloadDocument = lead => {
    this.setState({ loading: true });
    try {
      api.getDocument('leadforms', lead.contract, true);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };
  handleDownloadInvoiceDeal = deal => {
    this.setState({ loading: true });
    try {
      api.getDocument('dealinvoices', deal.invoice, true);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };
  handleDownloadDocumentDeal = deal => {
    this.setState({ loading: true });
    try {
      api.getDocument('dealforms', deal.contract, true);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };
  handleInvoiceLead = lead => {
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>
            By unlocking this lead you agree that an invoice will be created and
            sent to the email address provided by the company.
          </h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .invoiceLead(lead._id)
          .then(() => {
            that.props
              .findMyLeads()
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully unlocked lead');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };
  handleCompleteLead = e => {
    this.setState({ loading: true });
    this.props
      .completeLead(e._id)
      .then(() => {
        this.props
          .findMyLeads()
          .then(() => {
            this.setState({ loading: false });
            Notify('success', 'Successfully completed lead');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  render() {
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12}>
            <Icon
              style={{ fontSize: 25, color: 'rgb(4, 30, 66)', marginRight: 20 }}
              type="history"
            />
            <span
              style={{ fontWeight: 750, fontSize: 25, color: 'rgb(4, 30, 66)' }}
            >
              History
            </span>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Tabs
            style={{ overflow: 'visible' }}
            // onChange={this.handleTabChange}
          >
            <TabPane tab="Alarm Linkups & Specials" key="1">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <Table
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.props.deals}
                    onChange={this.handleSort}
                  >
                    <Column
                      {...this.getColumnSearchProps('displayId')}
                      title="ID"
                      dataIndex="displayId"
                      key="displayId"
                    />
                    <Column
                      title="Type"
                      dataIndex="type"
                      key="type"
                      filters={[
                        {
                          text: 'Linkup',
                          value: 'linkups'
                        },
                        {
                          text: 'Special',
                          value: 'specials'
                        }
                      ]}
                      filteredValue={filteredInfo.type || null}
                      onFilter={(value, record) => record.type.includes(value)}
                      render={(text, deal) => (
                        <span>{text === 'linkups' ? 'Linkup' : 'Special'}</span>
                      )}
                    />
                    <Column
                      title="Client Type"
                      dataIndex="clientType"
                      key="clientType"
                      filters={[
                        {
                          text: 'Business',
                          value: 'Business'
                        },
                        {
                          text: 'Residential',
                          value: 'Residential'
                        }
                      ]}
                      filteredValue={filteredInfo.clientType || null}
                      onFilter={(value, record) =>
                        record.clientType.includes(value)
                      }
                    />
                    <Column
                      {...this.getColumnSearchProps('companyName')}
                      title="Company Name"
                      dataIndex="companyName"
                      key="companyName"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientName')}
                      title="Client Name"
                      dataIndex="clientName"
                      key="clientName"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientSurname')}
                      title="Client Surname"
                      dataIndex="clientSurname"
                      key="clientSurname"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientNumber')}
                      title="Client Number"
                      dataIndex="clientNumber"
                      key="clientNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientEmail')}
                      title="Client Email"
                      dataIndex="clientEmail"
                      key="clientEmail"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientAddress')}
                      title="Client Address"
                      dataIndex="clientAddress"
                      key="clientAddress"
                    />
                    <Column
                      {...this.getColumnSearchProps('requestTimestamp')}
                      title="Request Date"
                      dataIndex="requestTimestamp"
                      key="requestTimestamp"
                    />
                    <Column
                      sorter={() => {}}
                      title="Completion Date"
                      dataIndex="closedDate"
                      key="closedDate"
                      render={(text, deal) => (
                        <span>
                          {moment(text).format('YYYY-MM-DD HH:MM:ss')}
                        </span>
                      )}
                    />
                    <Column
                      title="Actions"
                      render={(text, deal) => (
                        <Devide direction="vertical">
                          {deal.contract && (
                            <a
                              onClick={e =>
                                this.handleDownloadDocumentDeal(deal)
                              }
                            >
                              Download Contract
                            </a>
                          )}
                          {deal.invoice && (
                            <a
                              onClick={e =>
                                this.handleDownloadInvoiceDeal(deal)
                              }
                            >
                              Download Invoice
                            </a>
                          )}
                        </Devide>
                      )}
                    />
                  </Table>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Alarm Estimates" key="2">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <Table
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.props.estimateLeads}
                    onChange={this.handleSortAlarm}
                  >
                    <Column
                      {...this.getColumnSearchProps('displayId')}
                      title="ID"
                      dataIndex="displayId"
                      key="displayId"
                    />
                    {/* <Column
                      title='Type'
                      dataIndex='type'
                      key='type'
                      filters={[
                        {
                          text: 'Linkup',
                          value: 'linkups',
                        },
                        {
                          text: 'Special',
                          value: 'specials',
                        },
                      ]}
                      filteredValue={filteredInfo.type || null}
                      onFilter={(value, record) => record.type.includes(value)}
                      render={(text, deal) => (
                        <span>{text === 'linkups' ? 'Linkup' : 'Special'}</span>
                      )}
                    /> */}
                    <Column
                      title="Client Type"
                      dataIndex="clientType"
                      key="clientType"
                      filters={[
                        {
                          text: 'Business',
                          value: 'Business'
                        },
                        {
                          text: 'Residential',
                          value: 'Residential'
                        }
                      ]}
                      filteredValue={filteredInfo.clientType || null}
                      onFilter={(value, record) =>
                        record.clientType.includes(value)
                      }
                    />
                    <Column
                      {...this.getColumnSearchProps('companyName')}
                      title="Company Name"
                      dataIndex="companyName"
                      key="companyName"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientName')}
                      title="Client Name"
                      dataIndex="clientName"
                      key="clientName"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientSurname')}
                      title="Client Surname"
                      dataIndex="clientSurname"
                      key="clientSurname"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientNumber')}
                      title="Client Number"
                      dataIndex="clientNumber"
                      key="clientNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientEmail')}
                      title="Client Email"
                      dataIndex="clientEmail"
                      key="clientEmail"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientAddress')}
                      title="Client Address"
                      dataIndex="clientAddress"
                      key="clientAddress"
                    />
                    <Column
                      {...this.getColumnSearchProps('requestTimestamp')}
                      title="Request Date"
                      dataIndex="requestTimestamp"
                      key="requestTimestamp"
                    />
                    <Column
                      sorter={() => {}}
                      title="Completion Date"
                      dataIndex="closedDate"
                      key="closedDate"
                      render={(text, deal) => (
                        <span>
                          {moment(text).format('YYYY-MM-DD HH:MM:ss')}
                        </span>
                      )}
                    />
                    <Column
                      title="Actions"
                      render={(text, lead) =>
                        lead ? (
                          <div>
                            <span>
                              <a
                                onClick={e => this.handleDownloadDocument(lead)}
                              >
                                Download Estimate
                              </a>
                              <Divider type="vertical" />
                              <a
                                onClick={e => this.handleDownloadDocument(lead)}
                              >
                                Download Invoice
                              </a>
                            </span>
                          </div>
                        ) : null
                      }
                    />
                  </Table>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Guarding" key="3">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <Table
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.props.guardingLeads}
                    onChange={this.handleSortGuard}
                  >
                    <Column
                      sorter={() => {}}
                      title="ID"
                      dataIndex="displayId"
                      key="displayId"
                    />
                    <Column
                      title="Type"
                      dataIndex="period"
                      key="period"
                      filters={[
                        {
                          text: 'temp',
                          value: 'Temp'
                        },
                        {
                          text: 'month',
                          value: 'Month'
                        }
                      ]}
                      filteredValue={filteredInfo.type || null}
                      onFilter={(value, record) => record.type.includes(value)}
                      render={(text, deal) => (
                        <span>{text === 'temp' ? 'Temp' : 'Month'}</span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('clientName')}
                      title="Client Name"
                      dataIndex="clientName"
                      key="clientName"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientSurname')}
                      title="Client Surname"
                      dataIndex="clientSurname"
                      key="clientSurname"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientNumber')}
                      title="Client Number"
                      dataIndex="clientNumber"
                      key="clientNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientEmail')}
                      title="Client Email"
                      dataIndex="clientEmail"
                      key="clientEmail"
                    />
                    <Column
                      {...this.getColumnSearchProps('clientAddress')}
                      title="Client Address"
                      dataIndex="clientAddress"
                      key="clientAddress"
                    />
                    <Column
                      {...this.getColumnSearchProps('requestTimestamp')}
                      title="Request Date"
                      dataIndex="requestTimestamp"
                      key="requestTimestamp"
                    />
                    <Column
                      sorter={() => {}}
                      title="Completion Date"
                      dataIndex="closedDate"
                      key="closedDate"
                      render={(text, deal) => (
                        <span>
                          {moment(text).format('YYYY-MM-DD HH:MM:ss')}
                        </span>
                      )}
                    />
                    <Column
                      title="Actions"
                      render={(text, lead) =>
                        lead ? (
                          <div>
                            <span>
                              <a
                                onClick={e => this.handleDownloadDocument(lead)}
                              >
                                Download Contract
                              </a>
                              <Divider type="vertical" />
                              {/* <a
                                onClick={(e) =>
                                  this.handleDownloadInvoice(lead)
                                }
                              >
                                Download Invoice
                              </a> */}
                            </span>
                          </div>
                        ) : null
                      }
                    />
                  </Table>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Spin>
      </div>
    );
  }
}

export default History;
