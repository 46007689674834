import Notify from 'src/components/meta/Notification';
import { getToken } from 'src/utils/token';
import config from 'src/config';
import { b64toBlob } from 'src/utils/base64';

/**
 *
 * @param {"termsandconditions" | "leadforms" | "leadinvoices" | "dealforms" | "dealinvoices"} documentType
 * @param {string} documentId
 * @param {boolean | {filename?:string}} [download]
 */

async function getDocument(documentType, documentId, download = false) {
  try {
    const { base64, name } = await fetch(
      `${config.API}/documents/${documentType}/${documentId}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(res => res.json());

    if (download) {
      const blob = b64toBlob(base64, 'application/pdf');

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;

      a.download = download.filename || name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      Notify('success', `Successfully downloaded ${download.filename || name}`);
    }
    return base64;
  } catch (error) {
    Notify('error', error.message);
  }
}

export default { getDocument };
