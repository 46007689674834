import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  assignToUser,
  findAllUsers,
  findAllUserBranches
} from 'src/redux/actions/admin';
import {
  completeLead,
  findMyLeads,
  invoiceLead,
  updateFollowUp
} from 'src/redux/actions/leads';
import {
  completeDeal,
  findMyDeals,
  invoiceDeal,
  updateFollowUpDeal
} from 'src/redux/actions/deals';

class Leads extends Component {
  render = () => {
    const {
      Layout,
      findMyLeads,
      findMyDeals,
      invoiceLead,
      invoiceDeal,
      completeLead,
      completeDeal,
      updateFollowUp,
      updateFollowUpDeal,
      findAllUsers,
      findAllUserBranches,
      assignToUser,
      guardingLeads,
      estimateLeads,
      deals,
      user,
      usersBranch,
      push
    } = this.props;
    return (
      <Layout
        findMyLeads={findMyLeads}
        findMyDeals={findMyDeals}
        invoiceLead={invoiceLead}
        invoiceDeal={invoiceDeal}
        completeLead={completeLead}
        completeDeal={completeDeal}
        updateFollowUp={updateFollowUp}
        updateFollowUpDeal={updateFollowUpDeal}
        findAllUsers={findAllUsers}
        findAllUserBranches={findAllUserBranches}
        assignToUser={assignToUser}
        guardingLeads={guardingLeads}
        estimateLeads={estimateLeads}
        deals={deals}
        user={user}
        usersBranch={usersBranch}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  guardingLeads: state.leads.myGuardingLeads || [],
  estimateLeads: state.leads.myEstimateLeads || [],
  deals: state.deals.myDeals || [],
  users: state.admin.allUsers || [],
  usersBranch: state.admin.allUserBranches || [],
  user: state.user.user
});

const mapDispatchToProps = {
  findMyLeads,
  findMyDeals,
  invoiceLead,
  invoiceDeal,
  updateFollowUp,
  updateFollowUpDeal,
  completeLead,
  completeDeal,
  findAllUsers,
  findAllUserBranches,
  assignToUser,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Leads);
