import { Divider } from 'antd';
import React from 'react';

const Devide = ({ children, direction = 'vertical' }) => {
  if (!children) return null;
  if (children?.filter(Boolean)?.length === 0) return null;

  return (
    <>
      {children.filter(Boolean).map((child, i) => (
        <React.Fragment key={i}>
          {child}
          {i < children.length - 1 && <Divider type={direction} />}
        </React.Fragment>
      ))}
    </>
  );
};

export default Devide;
