import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  Modal,
  Row,
  Spin,
  Table,
  Pagination
} from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import Notify from 'src/components/meta/Notification';
import config from 'src/config';
const { Column } = Table;
const FormItem = Form.Item;

class UserFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Username</span>}>
          {this.props.form.getFieldDecorator('username', {
            rules: [{ required: true, message: 'Username required' }],
            initialValue: this.props.editUser
              ? this.props.editUser.username
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Full Name</span>}>
          {this.props.form.getFieldDecorator('name', {
            rules: [{ required: true, message: 'User name required' }],
            initialValue: this.props.editUser ? this.props.editUser.name : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Email</span>}>
          {this.props.form.getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Email required' },
              {
                type: 'email',
                message: 'A valid email is required.'
              }
            ],
            initialValue: this.props.editUser ? this.props.editUser.email : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Number</span>}>
          {this.props.form.getFieldDecorator('number', {
            rules: [{ required: true, message: 'Number required' }],
            initialValue: this.props.editUser ? this.props.editUser.number : ''
          })(<Input maxLength={10} />)}
        </FormItem>
        <FormItem label={<span>Super Admin</span>}>
          {this.props.form.getFieldDecorator('isSuperAdmin', {
            initialValue: this.props.editUser
              ? this.props.editUser.isSuperAdmin
              : false,
            valuePropName: 'checked'
          })(<Checkbox />)}
        </FormItem>
      </Form>
    );
  }
}
const UserForm = Form.create()(UserFormClass);
class Users extends React.Component {
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllUsers(1)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: '',
      usersPagination: 1
    };
  }

  stopProp = e => {
    e.stopPropagation();
  };
  onAddUser = e => {
    this.setState({ addUserVisible: true, editUser: undefined });
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let users = this.props.users;
    if (order === 'ascend') {
      users.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      users.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedInfo: sorter
    });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleDeleteUser = e => {
    const id = e.currentTarget.getAttribute('data');
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are your sure you want to delete this user?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteUser(id)
          .then(() => {
            that.props
              .findAllUsers(1)
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully deleted user');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };
  handleAddUser = e => {
    e.preventDefault();
    this.userForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editUser) {
          values._id = this.state.editUser._id;
        } else {
          values.type = 'admin';
          values.password = 'pass';
          values.firstLogin = true;
        }
        if (values.isSuperAdmin === '') {
          values.isSuperAdmin = false;
        }
        this.props
          .addUser(values)
          .then(() => {
            this.props
              .findAllUsers(1)
              .then(() => {
                if (this.state.editUser) {
                  Notify('success', 'Successfully saved user');
                } else {
                  Notify('success', 'Successfully added user');
                }
                this.userForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addUserVisible: false,
                  editUser: undefined
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };
  handleEditUser = e => {
    this.setState({ addUserVisible: true, editUser: e });
  };
  handleUsersPaginationPageChange = val => {
    // Change Pagination State
    this.setState({
      ...this.state,
      usersPagination: val
    });

    // Fetch Users based on page
    this.setState({ loading: true });
    this.props
      .findAllUsers(val)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  render() {
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12}>
            <Icon
              style={{ fontSize: 25, color: 'rgb(4, 30, 66)', marginRight: 20 }}
              type="team"
            />
            <span
              style={{ fontWeight: 750, fontSize: 25, color: 'rgb(4, 30, 66)' }}
            >
              Manage Users
            </span>
          </Col>
          <Col span={12}>
            <Button
              onClick={this.onAddUser}
              type="primary"
              style={{ float: 'right', marginRight: 15 }}
            >
              Add Admin User
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <Table
                style={{ background: 'white' }}
                rowKey="_id"
                size="middle"
                pagination={false}
                dataSource={this.props.users}
                onChange={this.handleSort}
              >
                {/* <Column title="ID" dataIndex="_id" key="_id" /> */}
                <Column
                  {...this.getColumnSearchProps('username')}
                  title="Username"
                  dataIndex="username"
                  key="username"
                />
                <Column
                  {...this.getColumnSearchProps('name')}
                  title="Name"
                  dataIndex="name"
                  key="name"
                />
                <Column
                  {...this.getColumnSearchProps('email')}
                  title="Email"
                  dataIndex="email"
                  key="email"
                />
                <Column
                  {...this.getColumnSearchProps('number')}
                  title="Number"
                  dataIndex="number"
                  key="number"
                />
                <Column
                  {...this.getColumnSearchProps('isSuperAdmin')}
                  title="Super Admin"
                  dataIndex="isSuperAdmin"
                  key="isSuperAdmin"
                  render={(text, user) => (
                    <span key={user._id}>
                      {user.isSuperAdmin ? 'True' : 'False'}
                    </span>
                  )}
                />
                <Column
                  title="Actions"
                  render={(text, user) => (
                    <span>
                      <a onClick={e => this.handleEditUser(user)}>Edit</a>

                      <Divider type="vertical" />
                      {user.username !== 'admin' ? (
                        <a data={user._id} onClick={this.handleDeleteUser}>
                          Delete
                        </a>
                      ) : null}
                    </span>
                  )}
                />
              </Table>
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Pagination
              onChange={this.handleUsersPaginationPageChange}
              defaultCurrent={1}
              current={this.state.usersPagination}
              pageSize={10}
              total={this.props.totalUsers}
            />
          </Row>
          <Modal
            title="Add Admin User"
            visible={this.state.addUserVisible}
            onOk={this.handleAddUser}
            okButtonProps={{ loading: this.state.loading }}
            cancelButtonProps={{ loading: this.state.loading }}
            onCancel={e => this.setState({ addUserVisible: false })}
            okText={this.state.editUser ? 'Save' : 'Add User'}
            cancelText="Cancel"
          >
            <UserForm
              editUser={this.state.editUser}
              wrappedComponentRef={form => (this.userForm = form)}
            />
          </Modal>
        </Spin>
      </div>
    );
  }
}

export default Users;
