import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  signIn,
  changePassword
} from 'src/redux/actions/user';

class SignIn extends Component {
  render = () => {
    const {
      Layout,
      signIn,
      changeLocale,
      push,
      changePassword,
      user
    } = this.props;

    return (
      <Layout
        signIn={signIn}
        changeLocale={changeLocale}
        push={push}
        changePassword={changePassword}
        user={user}
      />
    );
  };
}

const mapStateToProps = state => ({
  user: state.user.user
});

const mapDispatchToProps = {
  signIn,
  push,
  changePassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
