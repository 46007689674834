import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { findMyDeals } from 'src/redux/actions/deals';
import { findMyLeads } from 'src/redux/actions/leads';

class Leads extends Component {
  render = () => {
    const {
      Layout,
      findMyDeals,
      findMyLeads,
      guardingLeads,
      estimateLeads,
      deals,
      push
    } = this.props;
    return (
      <Layout
        findMyDeals={findMyDeals}
        findMyLeads={findMyLeads}
        guardingLeads={guardingLeads}
        estimateLeads={estimateLeads}
        deals={deals}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  guardingLeads: state.leads.myGuardingLeads || [],
  estimateLeads: state.leads.myEstimateLeads || [],
  deals: state.deals.myDeals || []
});

const mapDispatchToProps = {
  findMyDeals,
  findMyLeads,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Leads);
