export const initialState = {
  allUsers: [],
  allCompanies: [],
  allBranches: [],
  allUserBranches: []
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADMIN_FIND_CALENDAR': {
      if (action.data) {
        const calendar = action.data.calendar;
        return {
          ...state,
          calendar: calendar
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_DEALDETAILS': {
      if (action.data) {
        const dealDetails = action.data.dealDetails;

        return {
          ...state,
          dealDetails: dealDetails
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_DEALBIDINFO': {
      if (action.data) {
        const biddingInfo = action.data.biddingInfo;

        return {
          ...state,
          biddingInfo: biddingInfo
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLCOMPANIES': {
      if (action.data) {
        const allCompanies = action.data.allCompanies;
        const totalItems = action.data.totalItems;
        return {
          ...state,
          allCompanies: allCompanies,
          totalCompanies: totalItems
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLBRANCHES': {
      if (action.data) {
        const allBranches = action.data.allBranches;
        const totalItems = action.data.totalItems;
        return {
          ...state,
          allBranches: allBranches,
          totalBranches: totalItems
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_DEALDEALS': {
      if (action.data) {
        const dealDeals = action.data.dealDeals;
        return {
          ...state,
          dealDeals: dealDeals
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_APPROVEDDEALS': {
      if (action.data) {
        const approvedDeals = action.data.approvedDeals;
        let approvedDealIds = [];
        approvedDeals.forEach(deal => {
          approvedDealIds.push(deal._id);
        });
        return {
          ...state,
          approvedDeals: approvedDeals,
          approvedDealIds: approvedDealIds
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_AUCTIONHISTORYDEALS': {
      if (action.data) {
        const auctionHistoryDeals = action.data.auctionHistoryDeals;
        return {
          ...state,
          auctionHistoryDeals: auctionHistoryDeals
        };
      }
      return initialState;
    }
    case 'ADMIN_SET_DEALDETAILSID': {
      if (action.data) {
        const id = action.data;
        return {
          ...state,
          dealDetailsId: id
        };
      }
      return initialState;
    }
    case 'ADMIN_SET_FROMHISTORY': {
      if (action.data) {
        const boolean = action.data;
        return {
          ...state,
          fromHistory: boolean
        };
      }
      return initialState;
    }
    case 'ADMIN_BIDDING_UPDATE_LIST': {
      if (action.data) {
        let auctionDeals = state.auctionDeals;
        let updatedDeals = action.data.deals;
        auctionDeals.forEach(obj => {
          obj.highestBid = updatedDeals.find(o => o._id === obj._id).highestBid;
          obj.addAuctionMinutes = updatedDeals.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
        });
        const timeStamp = action.data.timeStamp;
        return {
          ...state,
          auctionDeals: auctionDeals,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    case 'ADMIN_BIDDING_UPDATE_SINGLE': {
      if (action.data) {
        let dealDetails = state.dealDetails || {};
        dealDetails.highestBid = action.data.deal.highestBid;
        dealDetails.addAuctionMinutes = action.data.deal.addAuctionMinutes;
        const timeStamp = action.data.timeStamp;

        return {
          ...state,
          dealDetails: dealDetails,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLUSERS': {
      if (action.data) {
        const allUsers = action.data.allUsers;
        const totalItems = action.data.totalItems;
        return {
          ...state,
          allUsers: allUsers,
          totalUsers: totalItems
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLUSERS_BRANCHES': {
      if (action.data) {
        const allUserBranches = action.data.allUserBranches;
        return {
          ...state,
          allUserBranches: allUserBranches
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLDEALERS': {
      if (action.data) {
        const allDealers = action.data.allDealers;
        return {
          ...state,
          allDealers: allDealers
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
