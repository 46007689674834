import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { findAllUsers, addUser, deleteUser } from 'src/redux/actions/admin';
import { sendNotification } from 'src/redux/actions/notifications';

class Users extends Component {
  render = () => {
    const {
      Layout,
      users,
      user,
      totalUsers,
      findAllUsers,
      addUser,
      deleteUser,
      sendNotification,
      push
    } = this.props;
    return (
      <Layout
        users={users}
        user={user}
        totalUsers={totalUsers}
        findAllUsers={findAllUsers}
        addUser={addUser}
        deleteUser={deleteUser}
        sendNotification={sendNotification}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  users: state.admin.allUsers || [],
  user: state.user.user || {},
  totalUsers: state.admin.totalUsers || 0
});

const mapDispatchToProps = {
  findAllUsers,
  addUser,
  deleteUser,
  sendNotification,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
