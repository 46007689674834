import {
  Col,
  Divider,
  Form,
  Icon,
  Input,
  Layout,
  Menu,
  Row,
  Select,
  Spin,
  Switch,
  Upload
} from 'antd';
import React from 'react';
import Resizer from 'react-image-file-resizer';
import { ButtonSquare } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
const numeral = require('numeral');
const { Content, Sider } = Layout;

const FormItem = Form.Item;
const Option = Select.Option;

/**
 * Creates an anchor element `<a></a>` with
 * the base64 pdf source and a filename with the
 * HTML5 `download` attribute then clicks on it.
 * @param  {string} dataUrl
 * @param  {string} fileNmae
 * @return {void}
 */
function downloadPDF(dataUrl, fileName = 'file.pdf') {
  const downloadLink = document.createElement('a');

  downloadLink.href = dataUrl;
  downloadLink.download = fileName;
  downloadLink.click();
}

const Account = props => {
  const logo = props.props.user.logo?.base64;
  const termsandconditions = props.props.user.document?.base64
    ? {
        name: props.props.user.document?.name || 'Terms and Conditions.pdf',
        url: props.props.user.document.base64,
        status: 'done',
        uid: '-1'
      }
    : undefined;

  const currentDocument = props.document || termsandconditions;

  return (
    <Form onSubmit={props.handleAccountInfoSave}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={24}>
          <FormItem
            label={<span style={{ color: '#6D6E70', fontSize: 16 }}>Logo</span>}
          >
            {props.props.form.getFieldDecorator('image', {
              rules: [{ required: !logo, message: 'Logo required' }]
            })(
              <div style={{ display: 'flex' }}>
                <Col style={{ margin: 'auto' }}>
                  <Upload
                    name="mainImage"
                    showUploadList={false}
                    beforeUpload={props.beforeUpload}
                    customRequest={props.handleImageUpload}
                    onChange={props.handleProfileImage}
                    accept="image/*"
                  >
                    <ButtonSquare style={{ margin: 'auto' }} type="primary">
                      Upload Logo Image
                    </ButtonSquare>
                    <p style={{ textAlign: 'center', marginTop: '5px' }}>
                      Upload jpeg, png, etc.
                      <br />
                      <span style={{ fontSize: '12px' }}>
                        (max size: 500kb)
                      </span>
                    </p>
                  </Upload>
                </Col>
                <div
                  style={{
                    border: '1px solid #00888e3b',
                    // height: 200,
                    width: 200,
                    margin: 'auto',
                    display: 'flex',
                    overflow: 'hidden'
                  }}
                >
                  {props.image || logo ? (
                    <img
                      style={{
                        margin: 'auto',
                        maxWidth: 200,
                        width: '100%',
                        objectFit: 'contain'
                      }}
                      alt="Logo"
                      src={props.image || logo}
                    />
                  ) : (
                    <span
                      style={{
                        margin: 'auto',
                        lineHeight: '200px',
                        fontSize: 40,
                        color: 'black'
                      }}
                    >
                      LOGO
                    </span>
                  )}
                </div>
              </div>
            )}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            label={
              <>
                <span
                  style={{
                    color: '#6D6E70',
                    fontSize: 16,
                    maxWidth: 'max-content',
                    lineHeight: 'normal'
                  }}
                >
                  Upload Terms and Conditions (PDF ~{' '}
                  <span
                    style={{
                      fontSize: '12px',
                      maxWidth: 'max-content',
                      lineHeight: 'normal'
                    }}
                  >
                    max size: 500kb
                  </span>
                  )
                </span>
              </>
            }
          >
            {props.props.form.getFieldDecorator('document', {
              rules: [
                {
                  required: !termsandconditions,
                  message: 'Terms and conditions required'
                }
              ]
            })(
              <Upload
                name="Document"
                multiple={false}
                fileList={[currentDocument].filter(Boolean)}
                showUploadList={true}
                customRequest={props.handleDocumentUpload}
                action={props.handleDocument}
                onRemove={props.removeDocument}
                accept="application/pdf"
                onPreview={() => {
                  if (currentDocument.url) {
                    downloadPDF(currentDocument.url, currentDocument.name);
                  }
                }}
              >
                <ButtonSquare type="primary">
                  Upload Terms and Conditions
                </ButtonSquare>
              </Upload>
            )}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Company Name
              </span>
            }
          >
            {props.props.form.getFieldDecorator('orgName', {
              initialValue: props.props.user.orgName
            })(<Input disabled={true} />)}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Branch Name
              </span>
            }
          >
            {props.props.form.getFieldDecorator('name', {
              rules: [{ required: true, message: 'Branch name required' }],
              initialValue: props.props.user.name
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>Number</span>
            }
          >
            {props.props.form.getFieldDecorator('number', {
              rules: [{ required: true, message: 'Number required' }],
              initialValue: props.props.user.number
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>Email</span>
            }
          >
            {props.props.form.getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Email required' },
                {
                  type: 'email',
                  message: 'A valid email is required.'
                }
              ],
              initialValue: props.props.user.email
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Notification Email (Optional)
              </span>
            }
          >
            {props.props.form.getFieldDecorator('notificationEmail', {
              rules: [
                { required: false, message: 'Email Optional' },
                {
                  type: 'email'
                  // message: 'A valid email is required.'
                }
              ],
              initialValue: props.props.user.notificationEmail
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Areas Covered
              </span>
            }
          >
            {props.props.form.getFieldDecorator('areas', {
              initialValue: props.props.user.areas
            })(
              <Select
                mode="multiple"
                disabled={true}
                showSearch={false}
                style={{ width: '100%' }}
                placeholder="Search areas"
                optionFilterProp="children"
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <FormItem>
          <ButtonSquare
            style={{}}
            className="submit-button"
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </ButtonSquare>
        </FormItem>
      </Row>
    </Form>
  );
};
const Password = props => {
  return (
    <Form onSubmit={props.handlePasswordChange}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={12}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator('newPass', {
              rules: [
                { required: true, message: 'New password required' },
                { validator: props.validateToNextPassword }
              ]
            })(
              <Input.Password
                onBlur={props.handleConfirmBlur}
                type="password"
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={12}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Confirm New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator('confirmPass', {
              rules: [
                { required: true, message: 'Confirm new password' },
                { validator: props.compareToFirstPassword }
              ]
            })(<Input.Password type="password" />)}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <FormItem>
          <ButtonSquare
            style={{}}
            className="submit-button"
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </ButtonSquare>
        </FormItem>
      </Row>
    </Form>
  );
};

const formItemLayout = {
  labelCol: { span: 14 },
  wrapperCol: { span: 10 }
};
const Services = props => {
  return (
    <Form {...formItemLayout} onSubmit={props.handleAccountInfoSave}>
      <Row type="flex" justify="space-around" align="middle">
        <Col span={12}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Linkups Offered
              </span>
            }
          >
            {props.props.form.getFieldDecorator('linkupsOffered', {
              initialValue: props.props.user.linkupsOffered,
              valuePropName: 'checked'
            })(
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
              />
            )}
          </FormItem>
        </Col>
        {props.props.form.getFieldValue('linkupsOffered') ? (
          <div>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Linkup Residential Installation Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator(
                  'linkupsPriceOnceOffResidential',
                  {
                    rules: [
                      {
                        required: props.props.form.getFieldValue(
                          'linkupsOffered'
                        )
                          ? true
                          : false,
                        message: 'Linkup installation price required'
                      }
                    ],
                    initialValue:
                      props.props.user.linkupsPriceOnceOffResidential
                  }
                )(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'linkupsPriceOnceOffResidential'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      linkupsPriceOnceOffResidential:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('linkupsOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Linkup Business Installation Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator(
                  'linkupsPriceOnceOffBusiness',
                  {
                    rules: [
                      {
                        required: props.props.form.getFieldValue(
                          'linkupsOffered'
                        )
                          ? true
                          : false,
                        message: 'Linkup installation price required'
                      }
                    ],
                    initialValue: props.props.user.linkupsPriceOnceOffBusiness
                  }
                )(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'linkupsPriceOnceOffBusiness'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      linkupsPriceOnceOffBusiness:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('linkupsOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Linkup Residential Monthly Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator(
                  'linkupsPricePerMonthResidential',
                  {
                    rules: [
                      {
                        required: props.props.form.getFieldValue(
                          'linkupsOffered'
                        )
                          ? true
                          : false,
                        message: 'Linkup monthly price required'
                      }
                    ],
                    initialValue:
                      props.props.user.linkupsPricePerMonthResidential
                  }
                )(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'linkupsPricePerMonthResidential'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      linkupsPricePerMonthResidential:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('linkupsOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Linkup Business Monthly Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator(
                  'linkupsPricePerMonthBusiness',
                  {
                    rules: [
                      {
                        required: props.props.form.getFieldValue(
                          'linkupsOffered'
                        )
                          ? true
                          : false,
                        message: 'Linkup monthly price required'
                      }
                    ],
                    initialValue: props.props.user.linkupsPricePerMonthBusiness
                  }
                )(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'linkupsPricePerMonthBusiness'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      linkupsPricePerMonthBusiness:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('linkupsOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Linkup Residential Contract Period
                  </span>
                }
              >
                {props.props.form.getFieldDecorator(
                  'linkupsPeriodResidential',
                  {
                    rules: [
                      {
                        required: props.props.form.getFieldValue(
                          'linkupsOffered'
                        )
                          ? true
                          : false,
                        message: 'Linkup contract period'
                      }
                    ],
                    initialValue: props.props.user.linkupsPeriodResidential
                  }
                )(
                  <Select
                    disabled={
                      props.props.form.getFieldValue('linkupsOffered')
                        ? false
                        : true
                    }
                    showSearch={false}
                    style={{ width: '100%' }}
                    placeholder="Contract period"
                    optionFilterProp="children"
                  >
                    <Option key="1" value="Month to month">
                      Month to month
                    </Option>
                    <Option key="3" value="3 Months">
                      3 Months
                    </Option>
                    <Option key="6" value="6 Months">
                      6 Months
                    </Option>
                    <Option key="12" value="12 Months">
                      12 Months
                    </Option>
                    <Option key="24" value="24 Months">
                      24 Months
                    </Option>
                    <Option key="36" value="36 Months">
                      36 Months
                    </Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Linkup Business Contract Period
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('linkupsPeriodBusiness', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue('linkupsOffered')
                        ? true
                        : false,
                      message: 'Linkup contract period'
                    }
                  ],
                  initialValue: props.props.user.linkupsPeriodBusiness
                })(
                  <Select
                    disabled={
                      props.props.form.getFieldValue('linkupsOffered')
                        ? false
                        : true
                    }
                    showSearch={false}
                    style={{ width: '100%' }}
                    placeholder="Contract period"
                    optionFilterProp="children"
                  >
                    <Option key="1" value="Month to month">
                      Month to month
                    </Option>
                    <Option key="3" value="3 Months">
                      3 Months
                    </Option>
                    <Option key="6" value="6 Months">
                      6 Months
                    </Option>
                    <Option key="12" value="12 Months">
                      12 Months
                    </Option>
                    <Option key="24" value="24 Months">
                      24 Months
                    </Option>
                    <Option key="36" value="36 Months">
                      36 Months
                    </Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </div>
        ) : null}
        <Divider type="horizontal" />
        <Col span={12}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Special Offered
              </span>
            }
          >
            {props.props.form.getFieldDecorator('specialsOffered', {
              initialValue: props.props.user.specialsOffered,
              valuePropName: 'checked'
            })(
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
              />
            )}
          </FormItem>
        </Col>
        {props.props.form.getFieldValue('specialsOffered') ? (
          <div>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Special Installation Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('specialsPriceOnceOff', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'specialsOffered'
                      )
                        ? true
                        : false,
                      message: 'Special price required'
                    }
                  ],
                  initialValue: props.props.user.specialsPriceOnceOff
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue('specialsPriceOnceOff')}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      specialsPriceOnceOff:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('specialsOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Special Amount of Passives
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('specialDetails.passives', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'specialsOffered'
                      )
                        ? true
                        : false,
                      message: 'Special passives required'
                    }
                  ],
                  initialValue: props.props.user.specialDetails
                    ? props.props.user.specialDetails.passives
                    : ''
                })(<Input type="Number" />)}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Special Monthly Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('specialsPricePerMonth', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'specialsOffered'
                      )
                        ? true
                        : false,
                      message: 'Special price required'
                    }
                  ],
                  initialValue: props.props.user.specialsPricePerMonth
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'specialsPricePerMonth'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      specialsPricePerMonth:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('specialsOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Special Amount of Door Contacts
                  </span>
                }
              >
                {props.props.form.getFieldDecorator(
                  'specialDetails.doorContacts',
                  {
                    rules: [
                      {
                        required: props.props.form.getFieldValue(
                          'specialsOffered'
                        )
                          ? true
                          : false,
                        message: 'Special door contacts required'
                      }
                    ],
                    initialValue: props.props.user.specialDetails
                      ? props.props.user.specialDetails.doorContacts
                      : ''
                  }
                )(<Input type="Number" />)}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Special Contract Period
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('specialsPeriod', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'specialsOffered'
                      )
                        ? true
                        : false,
                      message: 'Special contract period required'
                    }
                  ],
                  initialValue: props.props.user.specialsPeriod
                })(
                  <Select
                    disabled={
                      props.props.form.getFieldValue('specialsOffered')
                        ? false
                        : true
                    }
                    showSearch={false}
                    style={{ width: '100%' }}
                    placeholder="Contract period"
                    optionFilterProp="children"
                  >
                    <Option key="1" value="Month to month">
                      Month to month
                    </Option>
                    <Option key="3" value="3 Months">
                      3 Months
                    </Option>
                    <Option key="6" value="6 Months">
                      6 Months
                    </Option>
                    <Option key="12" value="12 Months">
                      12 Months
                    </Option>
                    <Option key="24" value="24 Months">
                      24 Months
                    </Option>
                    <Option key="36" value="36 Months">
                      36 Months
                    </Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Special Amount of Fixed Panics
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('specialDetails.panics', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'specialsOffered'
                      )
                        ? true
                        : false,
                      message: 'Special panics required'
                    }
                  ],
                  initialValue: props.props.user.specialDetails
                    ? props.props.user.specialDetails.panics
                    : ''
                })(<Input type="Number" />)}
              </FormItem>
            </Col>
          </div>
        ) : null}
        <Divider type="horizontal" />
        <Col span={12}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Guarding Offered
              </span>
            }
          >
            {props.props.form.getFieldDecorator('guardingOffered', {
              initialValue: props.props.user.guardingOffered,
              valuePropName: 'checked'
            })(
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
              />
            )}
          </FormItem>
        </Col>
        {props.props.form.getFieldValue('guardingOffered') ? (
          <div>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    A Grade Per Shift Over a Month
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('guardingPriceA', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'guardingOffered'
                      )
                        ? true
                        : false,
                      message: 'Price per guard required'
                    }
                  ],
                  initialValue: props.props.user.guardingPriceA
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue('guardingPriceA')}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      guardingPriceA:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('guardingOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    A Grade Per Shift Temp Site
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('guardingPriceATemp', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'guardingOffered'
                      )
                        ? true
                        : false,
                      message: 'Price per guard required'
                    }
                  ],
                  initialValue: props.props.user.guardingPriceATemp
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue('guardingPriceATemp')}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      guardingPriceATemp:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('guardingOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    B Grade Per Shift Over a Month
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('guardingPriceB', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'guardingOffered'
                      )
                        ? true
                        : false,
                      message: 'Price per guard required'
                    }
                  ],
                  initialValue: props.props.user.guardingPriceB
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue('guardingPriceB')}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      guardingPriceB:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('guardingOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    B Grade Per Shift Temp Site
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('guardingPriceBTemp', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'guardingOffered'
                      )
                        ? true
                        : false,
                      message: 'Price per guard required'
                    }
                  ],
                  initialValue: props.props.user.guardingPriceBTemp
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue('guardingPriceBTemp')}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      guardingPriceBTemp:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('guardingOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    C Grade Per Shift Over a Month
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('guardingPriceC', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'guardingOffered'
                      )
                        ? true
                        : false,
                      message: 'Price per guard required'
                    }
                  ],
                  initialValue: props.props.user.guardingPriceC
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue('guardingPriceC')}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      guardingPriceC:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('guardingOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    C Grade Per Shift Temp Site
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('guardingPriceCTemp', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'guardingOffered'
                      )
                        ? true
                        : false,
                      message: 'Price per guard required'
                    }
                  ],
                  initialValue: props.props.user.guardingPriceCTemp
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue('guardingPriceCTemp')}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      guardingPriceCTemp:
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('guardingOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col>
            {/* Removing D Grade */}
            {/* <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    D Grade Per Shift Over a Month
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('guardingPriceD', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'guardingOffered'
                      )
                        ? true
                        : false,
                      message: 'Price per guard required',
                    },
                  ],
                  initialValue: props.props.user.guardingPriceD,
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue('guardingPriceD')}
                  onChange={(e) => {
                    props.props.form.setFieldsValue({
                      guardingPriceD:
                        'R' + numeral(e.currentTarget.value).format('0,0'),
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('guardingOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col> */}
            {/* <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    D Grade Per Shift Temp Site
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('guardingPriceDTemp', {
                  rules: [
                    {
                      required: props.props.form.getFieldValue(
                        'guardingOffered'
                      )
                        ? true
                        : false,
                      message: 'Price per guard required',
                    },
                  ],
                  initialValue: props.props.user.guardingPriceDTemp,
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue('guardingPriceDTemp')}
                  onChange={(e) => {
                    props.props.form.setFieldsValue({
                      guardingPriceDTemp:
                        'R' + numeral(e.currentTarget.value).format('0,0'),
                    });
                  }}
                  disabled={
                    props.props.form.getFieldValue('guardingOffered')
                      ? false
                      : true
                  }
                />
              </FormItem>
            </Col> */}
          </div>
        ) : null}
        <Divider type="horizontal" />
        <Col span={12}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Estimate Pricing
              </span>
            }
          >
            {props.props.form.getFieldDecorator('estimateOffered', {
              initialValue: props.props.user.estimateOffered,
              valuePropName: 'checked'
            })(
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
              />
            )}
          </FormItem>
        </Col>
        {props.props.form.getFieldValue('estimateOffered') ? (
          <div>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Control Panel Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator(
                  'estimatePricing.controlPanel',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Control panel price required'
                      }
                    ],
                    initialValue: props.props.user.estimatePricing
                      ? props.props.user.estimatePricing.controlPanel
                      : ''
                  }
                )(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'estimatePricing.controlPanel'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.controlPanel':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Keypad Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('estimatePricing.keypad', {
                  rules: [{ required: true, message: 'Keypad price required' }],
                  initialValue: props.props.user.estimatePricing
                    ? props.props.user.estimatePricing.keypad
                    : ''
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'estimatePricing.keypad'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.keypad':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Expander Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator(
                  'estimatePricing.expander',
                  {
                    rules: [
                      { required: true, message: 'Expander price required' }
                    ],
                    initialValue: props.props.user.estimatePricing
                      ? props.props.user.estimatePricing.expander
                      : ''
                  }
                )(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'estimatePricing.expander'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.expander':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Siren Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('estimatePricing.siren', {
                  rules: [{ required: true, message: 'Siren price required' }],
                  initialValue: props.props.user.estimatePricing
                    ? props.props.user.estimatePricing.siren
                    : ''
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'estimatePricing.siren'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.siren':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Battery Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('estimatePricing.battery', {
                  rules: [
                    { required: true, message: 'Battery price required' }
                  ],
                  initialValue: props.props.user.estimatePricing
                    ? props.props.user.estimatePricing.battery
                    : ''
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'estimatePricing.battery'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.battery':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Transformer Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator(
                  'estimatePricing.transformer',
                  {
                    rules: [
                      { required: true, message: 'Transformer price required' }
                    ],
                    initialValue: props.props.user.estimatePricing
                      ? props.props.user.estimatePricing.transformer
                      : ''
                  }
                )(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'estimatePricing.transformer'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.transformer':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Passive Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('estimatePricing.passive', {
                  rules: [
                    { required: true, message: 'Passive price required' }
                  ],
                  initialValue: props.props.user.estimatePricing
                    ? props.props.user.estimatePricing.passive
                    : ''
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'estimatePricing.passive'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.passive':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Door Contact Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator(
                  'estimatePricing.doorContact',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Door contact price required'
                      }
                    ],
                    initialValue: props.props.user.estimatePricing
                      ? props.props.user.estimatePricing.doorContact
                      : ''
                  }
                )(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'estimatePricing.doorContact'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.doorContact':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Fixed Panic Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('estimatePricing.panic', {
                  rules: [
                    { required: true, message: 'Fixed panic price required' }
                  ],
                  initialValue: props.props.user.estimatePricing
                    ? props.props.user.estimatePricing.panic
                    : ''
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'estimatePricing.panic'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.panic':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Metal Box Price
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('estimatePricing.box', {
                  rules: [
                    { required: true, message: 'Metal box price required' }
                  ],
                  initialValue: props.props.user.estimatePricing
                    ? props.props.user.estimatePricing.box
                    : ''
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue('estimatePricing.box')}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.box':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Labour Price (Per Point)
                  </span>
                }
              >
                {props.props.form.getFieldDecorator('estimatePricing.labour', {
                  rules: [{ required: true, message: 'Labour price required' }],
                  initialValue: props.props.user.estimatePricing
                    ? props.props.user.estimatePricing.labour
                    : ''
                })(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'estimatePricing.labour'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.labour':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={
                  <span style={{ color: '#6D6E70', fontSize: 16 }}>
                    Consumable Price (Per Point)
                  </span>
                }
              >
                {props.props.form.getFieldDecorator(
                  'estimatePricing.consumable',
                  {
                    rules: [
                      { required: true, message: 'Consumable price required' }
                    ],
                    initialValue: props.props.user.estimatePricing
                      ? props.props.user.estimatePricing.consumable
                      : ''
                  }
                )(<Input style={{ display: 'none' }} />)}
                <Input
                  value={props.props.form.getFieldValue(
                    'estimatePricing.consumable'
                  )}
                  onChange={e => {
                    props.props.form.setFieldsValue({
                      'estimatePricing.consumable':
                        'R' + numeral(e.currentTarget.value).format('0,0')
                    });
                  }}
                />
              </FormItem>
            </Col>
          </div>
        ) : null}

        <Divider type="horizontal" />
        <Col span={12}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Service Offered 1 (Max 155 characters)
              </span>
            }
          >
            {props.props.form.getFieldDecorator('serviceOffered1', {
              initialValue: props.props.user.serviceOffered1
            })(<Input maxLength={155} />)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Service Offered 2 (Max 155 characters)
              </span>
            }
          >
            {props.props.form.getFieldDecorator('serviceOffered2', {
              initialValue: props.props.user.serviceOffered2
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Service Offered 3 (Max 155 characters)
              </span>
            }
          >
            {props.props.form.getFieldDecorator('serviceOffered3', {
              initialValue: props.props.user.serviceOffered3
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Service Offered 4 (Max 155 characters)
              </span>
            }
          >
            {props.props.form.getFieldDecorator('serviceOffered4', {
              initialValue: props.props.user.serviceOffered4
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Service Offered 5 (Max 155 characters)
              </span>
            }
          >
            {props.props.form.getFieldDecorator('serviceOffered5', {
              initialValue: props.props.user.serviceOffered5
            })(<Input />)}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <FormItem>
          <ButtonSquare
            style={{}}
            className="submit-button"
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </ButtonSquare>
        </FormItem>
      </Row>
    </Form>
  );
};
class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeView: 'account'
    };
  }
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPass')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPass'], { force: true });
    }
    callback();
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  handleAccountInfoSave = event => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values._id = this.props.user._id;
        if (values.image) {
          values.image = this.state.image;
        }
        if (values.document) {
          values.document = this.state.document;
        }
        this.props
          .saveAccountInfo(values)
          .then(() => {
            this.setState({ loading: false });
            Notify(
              'success',
              'Success',
              'Successfully saved account information'
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', 'Error', e);
          });
      }
    });
  };
  handlePasswordChange = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values.password = values.newPass;
        this.props
          .changePassword(values)
          .then(() => {
            this.setState({ loading: false });
            Notify('success', 'Success', 'Successfully changed password');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', 'Error', e);
          });
      }
    });
  };
  handleProfileImage = info => {
    const isLt2M = info.file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      Notify('error', 'Image must be smaller than 500Kb');
      return;
    }
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        image: imageUrl
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    return false;
  };

  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 200, 200, 'PNG', 100, 0, uri => {
      callback(uri);
    });
  };
  removeImage = e => {
    this.setState({
      image: undefined
    });
  };

  handleDocument = file => {
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      Notify('error', 'Document must be smaller than 500Kb');
      return;
    }
    this.setState({
      loading: true
    });
    this.getBase64Doc(file, b64 => {
      this.setState({
        loading: false,
        document: {
          uid: '-1',
          name: file.name,
          status: 'done',
          url: b64
        }
      });
    });
  };
  removeDocument = e => {
    this.setState({
      document: this.props.user.document
    });
  };
  handleDocumentUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  getBase64Doc = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      callback(reader.result);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  };

  render() {
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12} style={{ marginBottom: 20 }}>
            <Icon
              style={{ fontSize: 25, color: 'rgb(4, 30, 66)', marginRight: 20 }}
              type="setting"
              theme="filled"
            />
            <span
              style={{ fontWeight: 750, fontSize: 25, color: 'rgb(4, 30, 66)' }}
            >
              Branch Details
            </span>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <Layout>
                <Layout>
                  <Sider
                    trigger={null}
                    width={120}
                    style={{
                      zIndex: 9,
                      background: 'white',
                      left: 0,
                      borderTopLeftRadius: 10,
                      borderRight: '1px solid #E5E5E5'
                    }}
                  >
                    <Menu
                      onClick={e => {
                        this.setState({ activeView: e.key });
                      }}
                      style={{ background: 'none', border: 0 }}
                      theme="light"
                      mode="inline"
                      defaultSelectedKeys={['account']}
                    >
                      <Menu.Item key="account">
                        <span style={{ fontSize: 16 }}>General</span>
                      </Menu.Item>
                      <Menu.Item key="services">
                        <span style={{ fontSize: 16 }}>Services</span>
                      </Menu.Item>
                      <Menu.Item key="password">
                        <span style={{ fontSize: 16 }}>Password</span>
                      </Menu.Item>
                    </Menu>
                  </Sider>
                  <Layout>
                    <Content
                      style={{
                        background: 'white',
                        padding: 40,
                        borderTopRightRadius: 10
                      }}
                    >
                      {this.state.activeView === 'account' ? (
                        <Account
                          props={this.props}
                          image={this.state.image}
                          document={this.state.document}
                          handleImageUpload={this.handleImageUpload}
                          handleProfileImage={this.handleProfileImage}
                          beforeUpload={this.beforeUpload}
                          handleAccountInfoSave={this.handleAccountInfoSave}
                          removeImage={this.removeImage}
                          handleDocumentUpload={this.handleDocumentUpload}
                          handleDocument={this.handleDocument}
                          removeDocument={this.removeDocument}
                        />
                      ) : this.state.activeView === 'services' ? (
                        <Services
                          props={this.props}
                          state={this.state}
                          that={this}
                          handleAccountInfoSave={this.handleAccountInfoSave}
                        />
                      ) : this.state.activeView === 'password' ? (
                        <Password
                          props={this.props}
                          handlePasswordChange={this.handlePasswordChange}
                          compareToFirstPassword={this.compareToFirstPassword}
                          validateToNextPassword={this.validateToNextPassword}
                          handleConfirmBlur={this.handleConfirmBlur}
                        />
                      ) : null}
                    </Content>
                  </Layout>
                </Layout>
              </Layout>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
const WrappedAccountSettings = Form.create()(AccountSettings);
export default WrappedAccountSettings;
