import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import TemplateNothing from 'src/components/layout/TemplateNothing';
import TemplateSidebar from 'src/components/layout/TemplateSidebar';

import SignInContainer from 'src/containers/SignIn';
import SignInComponent from 'src/components/views/SignIn';

import DealerDealsContainer from 'src/containers/restricted/dealer/Deals';
import DealerDealsComponent from 'src/components/views/dealer/Deals';
import DealerLeadsContainer from 'src/containers/restricted/dealer/Leads';
import DealerLeadsComponent from 'src/components/views/dealer/Leads';
import DealerAccountSettingsContainer from 'src/containers/restricted/dealer/AccountSettings';
import DealerAccountSettingsComponent from 'src/components/views/dealer/AccountSettings';
import DealerHistoryContainer from 'src/containers/restricted/dealer/History';
import DealerHistoryComponent from 'src/components/views/dealer/History';

import AdminUsersContainer from 'src/containers/restricted/admin/Users';
import AdminUsersComponent from 'src/components/views/admin/Users';
import AdminCompaniesContainer from 'src/containers/restricted/admin/Companies';
import AdminCompaniesComponent from 'src/components/views/admin/Companies';
import AdminDealsContainer from 'src/containers/restricted/admin/Deals';
import AdminDealsComponent from 'src/components/views/admin/Deals';
import AdminLeadsContainer from 'src/containers/restricted/admin/Leads';
import AdminLeadsComponent from 'src/components/views/admin/Leads';
import AdminHistoryContainer from 'src/containers/restricted/admin/History';
import AdminHistoryComponent from 'src/components/views/admin/History';
// import AdminAccountSettingsContainer from 'src/containers/restricted/admin/AccountSettings';
// import AdminAccountSettingsComponent from 'src/components/views/admin/AccountSettings';

const checkLoggedIn = state => {
  const cookies = new Cookies();
  if (cookies.get('token') && state.user.user) {
    return true;
  } else {
    return false;
  }
};
const RestrictedRoute = ({
  layout,
  template: Template,
  container: Container,
  component: Component,
  isLoggedIn,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Template {...props} background={rest.background}>
          <Container {...props} Layout={layout} />
        </Template>
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const OpenRoute = ({
  layout,
  template: Template,
  container: Container,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Template {...props} background={rest.background}>
        <Container {...props} Layout={layout} />
      </Template>
    )}
  />
);
const Routes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <OpenRoute
          path="/signin"
          template={TemplateNothing}
          container={SignInContainer}
          layout={SignInComponent}
        />
        {/* <OpenRoute
          path="/signup"
          template={TemplateNothing}
          container={SignUpContainer}
          layout={SignUpComponent}
        /> */}
        <OpenRoute
          exact
          path="/"
          template={TemplateNothing}
          container={SignInContainer}
          layout={SignInComponent}
        />
        <RestrictedRoute
          path="/admin/users"
          template={TemplateSidebar}
          container={AdminUsersContainer}
          layout={AdminUsersComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/companies"
          template={TemplateSidebar}
          container={AdminCompaniesContainer}
          layout={AdminCompaniesComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        {/* Remove Route For Closed Deals */}
        {/* <RestrictedRoute
          path="/admin/deals"
          template={TemplateSidebar}
          container={AdminDealsContainer}
          layout={AdminDealsComponent}
          isLoggedIn={isLoggedIn}
          background
        /> */}
        <RestrictedRoute
          path="/admin/leads"
          template={TemplateSidebar}
          container={AdminLeadsContainer}
          layout={AdminLeadsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/history"
          template={TemplateSidebar}
          container={AdminHistoryContainer}
          layout={AdminHistoryComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        {/* Remove Closed Deals Route */}
        {/* <RestrictedRoute
          path='/branch/deals'
          template={TemplateSidebar}
          container={DealerDealsContainer}
          layout={DealerDealsComponent}
          isLoggedIn={isLoggedIn}
          background
        /> */}
        <RestrictedRoute
          path="/branch/leads"
          template={TemplateSidebar}
          container={DealerLeadsContainer}
          layout={DealerLeadsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/branch/account"
          template={TemplateSidebar}
          container={DealerAccountSettingsContainer}
          layout={DealerAccountSettingsComponent}
          isLoggedIn={isLoggedIn}
          background
        />

        <RestrictedRoute
          path="/branch/history"
          template={TemplateSidebar}
          container={DealerHistoryContainer}
          layout={DealerHistoryComponent}
          isLoggedIn={isLoggedIn}
          background
        />
      </Switch>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: checkLoggedIn(state)
}))(Routes);
